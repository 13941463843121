import './style.scss';
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { useEffect, useState } from 'react';


const Slider = ({
    items = [],
    arrows = false,
    dots = false,
    slideInitial = 0,
    slidesPerView = 4,
    interval = 30_000
}) => {
    const [_slidesPerView, setSlidesPerView] = useState(slidesPerView);

    const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);

    const [sliderRef, instanceRef] = useKeenSlider({
        loop: true,
        initial: 0,
        slides: {
            perView: _slidesPerView,
            spacing: _slidesPerView === 1 ? 0 : 20,
        },
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        },
        created(s) {
            setLoaded(true);
        },
    }, [
        (slider) => {
            if (interval === 0) {
                return;
            }
            
            let timeout
            let mouseOver = false
            
            function clearNextTimeout() {
                clearTimeout(timeout)
            }
          
            function nextTimeout() {
                clearTimeout(timeout);
                if (mouseOver) return;
                timeout = setTimeout(() => {
                    slider.next()
                }, interval);
            }

            slider.on("created", () => {
                slider.container.addEventListener("mouseover", () => {
                    mouseOver = true;
                    clearNextTimeout();
                });

                slider.container.addEventListener("mouseout", () => {
                    mouseOver = false;
                    nextTimeout();
                });
                nextTimeout();
            });

            slider.on("dragStarted", clearNextTimeout);
            slider.on("animationEnded", nextTimeout);
            slider.on("updated", nextTimeout);
        },
    ]);


    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;

            if (width <= 600) {
                setSlidesPerView(1);
            } else if (width <= 900) {
                var __slidesPerView = Math.max(slidesPerView / 2, 1);
                setSlidesPerView(__slidesPerView);
            } else {
                setSlidesPerView(slidesPerView);
            }

            instanceRef.current.update();
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [slidesPerView, instanceRef]);


    useEffect(() => {
        instanceRef.current.moveToIdx(slideInitial);
    }, [instanceRef, slideInitial]);

    
    return <>
        <div className="slider-default">
            <div ref={sliderRef} className="keen-slider slider-default">
                {
                    items.map((item, index) => {
                        return <div className="keen-slider__slide" key={index}>
                            {item}
                        </div>;
                    })
                }
            </div>

            {loaded && instanceRef.current && arrows && (
                <>
                    <Arrow left onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()} />
                    <Arrow onClick={(e) => e.stopPropagation() || instanceRef.current?.next()} />
                </>
            )}
        </div>

        {loaded && instanceRef.current && dots && (
            <div className="dots">
                {[...Array(instanceRef.current.track.details.slides.length).keys(),].map((idx) => {
                    return (
                        <button
                            key={idx}
                            onClick={() => {
                                instanceRef.current?.moveToIdx(idx)
                            }}
                            className={"dot" + (currentSlide === idx ? " active" : "")}
                        />
                    );
                })}
            </div>
        )}
    </>;
}

export default Slider;


const Arrow = (props) => {
    const disabled = props.disabled ? " arrow--disabled" : "";
    
    return (
        <svg
            onClick={props.onClick}
            className={`arrow ${props.left ? "arrow--left" : "arrow--right"} ${disabled}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            {
                props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
            }

            {
                !props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
            }
        </svg>
    );
}