import './style.scss';
import { Box, Button, Grid, Typography } from "@mui/material";
import { useLanguageContext } from '../../../../contexts/LanguageContext';
import DefaultConstants from '../../../../data/Constants';
import { history } from '../../../../router/BrowserHistory';
import Clients from '../../../../components/Clients';
import Testimonials from '../../../../components/Testimonials';
import Slider from '../../../../components/Slider';
import Employees from '../../../../components/Employees';


const Home = (props) => {
    const { translate } = useLanguageContext();

    return <Grid container id='home-section'>
        <Grid item xs={12} className='grid-content-section padding-none'>
            <Box className='wallpaper'>
                <Slider
                    dots={true}
                    slidesPerView={1}
                    items={DefaultConstants.home.slides.map((item, index) => {
                        const align = `textalign-${item.align} alignitems-${item.align}`;

                        return <Box 
                            key={index} 
                            className={`wallpaper-item ${align}`}
                            sx={{
                                backgroundImage: `url(${item.background})`
                            }}
                        >
                            {
                                item.text.title && <Typography className='wallpaper-text-title'>
                                    {translate(item.text.title)}
                                </Typography>
                            }

                            {
                                item.text.message && translate(item.text.message).split('<br>').map((m, index2) => {
                                    return <Typography className='wallpaper-text-message' key={index2}>{m}</Typography>;
                                })
                            }

                            {
                                item.button && <Box className={align}>
                                    <Button 
                                        fullWidth={false}
                                        className='wallpaper-button'
                                        onClick={() => {
                                            const link = item.button.link;

                                            if (item.button.internal) {
                                                history.push(link);
                                                return;
                                            }

                                            window.open(link);
                                        }}
                                    >
                                        {translate(item.button.text)}
                                    </Button>
                                </Box>
                            }
                        </Box>;
                    })}
                />
            </Box>
        </Grid>

        <Grid item xs={12} className='grid-content-section padding-none'>
            <Grid container className='products-container'>
                {
                    DefaultConstants.products.items.map((i, index) => {
                        return <Grid item md={3} sm={12} className='product-item' key={index}>
                            <img src={i.icon} alt={i.name} className='product-item-image' />

                            <Box>
                                <Typography className='product-item-name'>{translate(i.name)}</Typography>
                                <Typography className='product-item-description'>{translate(i.homeDescription)}</Typography>
                            </Box>
                        </Grid>
                    })
                }
            </Grid>
        </Grid>

        <Grid item xs={12} className='grid-content-section'>
            <Typography className='section-title'>
                {translate('ourServices')}
            </Typography>

            <Box className='service-list'>
                {
                    DefaultConstants.services.items.map((s, index) => {
                        return <Box className='service-item' key={index} onClick={() => history.push(`/services/${s.id}`)}>
                            <Box className='service-item-image' sx={{ backgroundImage: `url(${s.wallpaper})` }} />
                            <Typography className='service-item-name'> {translate(s.name)} </Typography>
                            <Typography className='service-item-description'> {translate(s.homeDescription)} </Typography>
                        </Box>;
                    })
                }
            </Box>
        </Grid>

        <Grid item xs={12} className='grid-content-section backgroundcolor-grey'>
            <Typography className='section-title'>
                {translate('ourTeam')}
            </Typography>

            <Employees />
        </Grid>

        <Grid item xs={12} className='grid-content-section backgroundcolor-primary'>
            <Typography className='textalign-center fontsize-20 lineheight-22 color-white display-flex flexdirection-column gap-12'>
                {translate('employeesDescription1')}
            </Typography>
        </Grid>

        <Grid item xs={12} className='grid-content-section'>
            <Typography className='section-title'>
                {translate('ourCatalog')}
            </Typography>

            <Box className='catalog-list'>
                <iframe 
                    src={DefaultConstants.catalog.url} 
                    title={DefaultConstants.catalog.title} 
                />
            </Box>
        </Grid>

        <Grid item xs={12} className='grid-content-section'>
            <Typography className='section-title'>
                {translate('testimonials')}
            </Typography>

            <Testimonials />
        </Grid>

        <Grid item xs={12} className='grid-content-section'>
            <Typography className='section-title'>
                {translate('ourClients')}
            </Typography>

            <Clients />
        </Grid>
    </Grid>;
}


export default Home;