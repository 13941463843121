import { EmailOutlined } from "@mui/icons-material";
import DefaultConstants from "../data/Constants";
import { history } from "../router/BrowserHistory";

const WebsiteTranslation = {
    aboutDescription1: {
        pt_br: [
            <>A Saga Systems Brasil é uma empresa de capital 100% brasileiro, voltada a atender com excelência de produtos e serviços o mercado de Hospitalidade como Hotéis, Hospitais, Escolas, etc. Moderna e inovadora foi criada com o intuito de atingir uma meta ambiciosa: atuar com foco na excelência de serviços e no desenvolvimento de produtos para este mercado.</>,
            <>Nossa expertise está em trabalhar com dedicação e respeito a seus clientes, transmitindo confiança que só uma empresa experiente pode oferecer.</>,
            <>Em sintonia com o mercado, conectando clientes e empresas em um relacionamento sólido, baseado em experiência e profissionalismo.</>,
            <>Nossa equipe é composta por profissionais competentes e especialistas em suas respectivas áreas de atuação. Aliando a isso, nossos produtos são cuidadosamente desenvolvidos e certificados pelas normas de qualidade e comprovação de desempenho.</>,
            <>Ao unir tais características aos seus preços competitivos, a Saga Systems Brasil constrói diferenciais que a posicionam como a melhor alternativa para quem não quer correr riscos na hora de tomar a seguinte decisão: obter o melhor custo x benefício para seus novos investimentos.</>,
            <>No projeto <a href="https://online.fliphtml5.com/rsatm/kejv/" target="_blank" rel="noreferrer">Saga + ESG</a>, o objetivo é assegurar um mundo melhor às futuras gerações.</>,
            <>Os recursos naturais são finitos, o que pede sua atenção para não comprometer a sobrevivência das próximas gerações.</>,
            <>Conectar pessoas à segurança, praticidade e tecnologia, faz parte da Saga Systems.</>,
            <>Venha fazer parte dessa conexão.</>,
        ],
        en_us: [
            <>Saga Systems Brasil is a 100% Brazilian-owned company, focused on providing excellence in products and services to the Hospitality market such as Hotels, Hospitals, Schools, etc. Modern and innovative, it was created with the goal of achieving an ambitious target: to operate with a focus on service excellence and product development for this market.</>,
            <>Our expertise lies in working with dedication and respect for our clients, providing the trust that only an experienced company can offer.</>,
            <>In tune with the market, connecting customers and companies in a solid relationship based on experience and professionalism.</>,
            <>Our team consists of competent professionals and specialists in their respective fields. Additionally, our products are carefully developed and certified according to quality standards and performance testing.</>,
            <>By combining these characteristics with competitive prices, Saga Systems Brasil creates differentiators that position it as the best alternative for those who do not want to take risks when making the following decision: getting the best cost-benefit for new investments.</>,
            <>In the <a href="https://online.fliphtml5.com/rsatm/kejv/" target="_blank" rel="noreferrer">Saga + ESG</a> project, the goal is to ensure a better world for future generations.</>,
            <>Natural resources are finite, which calls for attention not to compromise the survival of future generations.</>,
            <>Connecting people to safety, practicality, and technology is part of Saga Systems.</>,
            <>Come and be part of this connection.</>,
        ],
        es_es: [
            <>Saga Systems Brasil es una empresa de capital 100% brasileño, enfocada en proporcionar excelencia en productos y servicios al mercado de la hospitalidad, como hoteles, hospitales, escuelas, etc. Moderna e innovadora, fue creada con el objetivo de lograr una meta ambiciosa: operar con un enfoque en la excelencia de los servicios y el desarrollo de productos para este mercado.</>,
            <>Nuestra experiencia radica en trabajar con dedicación y respeto por nuestros clientes, brindando la confianza que solo una empresa experimentada puede ofrecer.</>,
            <>En sintonía con el mercado, conectando clientes y empresas en una relación sólida basada en la experiencia y el profesionalismo.</>,
            <>Nuestro equipo está compuesto por profesionales competentes y especialistas en sus respectivos campos. Además, nuestros productos son cuidadosamente desarrollados y certificados según los estándares de calidad y pruebas de rendimiento.</>,
            <>Al combinar estas características con precios competitivos, Saga Systems Brasil crea diferenciadores que la posicionan como la mejor alternativa para aquellos que no quieren correr riesgos al tomar la siguiente decisión: obtener el mejor costo-beneficio para nuevas inversiones.</>,
            <>En el proyecto <a href="https://online.fliphtml5.com/rsatm/kejv/" target="_blank" rel="noreferrer">Saga + ESG</a>, el objetivo es garantizar un mundo mejor para las generaciones futuras.</>,
            <>Los recursos naturales son finitos, lo que requiere atención para no comprometer la supervivencia de las próximas generaciones.</>,
            <>Conectar a las personas con la seguridad, la practicidad y la tecnología es parte de Saga Systems.</>,
            <>Únase a esta conexión.</>,
        ]
    }, 

    aboutDescription2: {
        pt_br: `Somos uma empresa de produtos e serviços que tem a missão de aperfeiçoar a segurança, a conveniência e a sustentabilidade no ramo de hospitalidade`,
        en_us: `We are a products and services company with a mission to enhance security, convenience, and sustainability in the hospitality sector.`,
        es_es: `Somos una empresa de productos y servicios con la misión de mejorar la seguridad, la conveniencia y la sostenibilidad en el sector de la hospitalidad.`
    }, 

    aboutDescription3: {
        pt_br: `Ser reconhecida pelo mercado como a melhor empresa de produtos e serviços para sustentabilidade, conveniência e segurança no ramo de hospitalidade através da constante pesquisa e desenvolvimento de tecnologias.
            <br>Nossos valores são: Ética, sustentabilidade e inovação.`,
        en_us: `To be recognized by the market as the best company in products and services for sustainability, convenience, and security in the hospitality sector through constant research and development of technologies.
            <br>Our values are: Ethics, sustainability, and innovation.`,
        es_es: `Ser reconocida por el mercado como la mejor empresa de productos y servicios para la sostenibilidad, la conveniencia y la seguridad en el sector de la hospitalidad a través de la investigación y el desarrollo constantes de tecnologías.
            <br>Nuestros valores son: Ética, sostenibilidad e innovación.`
    }, 

    aboutDescription4: {
        pt_br: `A Saga Systems é sua parceira para levar e desenvolver produtos de alta qualidade com a finalidade de aumentar a segurança em controle de acesso, com as fechaduras eletrônicas, e dos pertences de seus hóspedes, com os cofres eletrônicos, e de acentuar o controle de consumo energético de empreendimentos, com os economizadores de energia.
            <br>Valendo-se de uma equipe com profissionais com mais de 30 anos de experiência no mercado hoteleiro, temos background para levar para seu hotel o que há de mais sofisticado e confiável em soluções de controle de acesso, segurança e eficiência energética.`,
        en_us: `Saga Systems is your partner in delivering and developing high-quality products aimed at enhancing access control security, with electronic locks, guest belongings safety with electronic safes, and improving energy consumption control in enterprises with energy savers.
            <br>With a team of professionals with over 30 years of experience in the hospitality market, we have the background to bring to your hotel the most sophisticated and reliable solutions in access control, security, and energy efficiency.`,
        es_es: `Saga Systems es su socio para llevar y desarrollar productos de alta calidad con el objetivo de aumentar la seguridad en el control de acceso, con cerraduras electrónicas, la seguridad de los bienes de sus huéspedes con cajas fuertes electrónicas, y mejorar el control del consumo energético en los emprendimientos con ahorradores de energía.
            <br>Con un equipo de profesionales con más de 30 años de experiencia en el mercado hotelero, tenemos la experiencia necesaria para llevar a su hotel las soluciones más sofisticadas y confiables en control de acceso, seguridad y eficiencia energética.`
    }, 
    
    aboutShortDescription: {
        pt_br: `A Saga Home orgulha-se de ser a vanguarda da inovação e segurança no universo das fechaduras eletrônicas. Integrando o renomado grupo Saga Systems, nossa empresa tem se destacado no mercado por oferecer soluções avançadas e personalizadas em sistemas de segurança residencial e comercial. Com um compromisso inabalável com a qualidade e a satisfação do cliente, a Saga Home tem revolucionado o modo como as pessoas interagem com os espaços, proporcionando tranquilidade e conveniência sem precedentes.
            <br>Nossos produtos são o resultado de extensas pesquisas e desenvolvimento, incorporando tecnologias de ponta que garantem não apenas a segurança dos ambientes, mas também a facilidade de uso. Desde fechaduras eletrônicas inteligentes que podem ser controladas por dispositivos móveis até sistemas avançados de reconhecimento biométrico, a Saga Home está na linha de frente da inovação em segurança. Nosso portfólio diversificado atende a uma ampla gama de necessidades, garantindo que cada cliente encontre a solução perfeita para sua casa ou negócio.
            <br>Comprometida com a excelência, a Saga Home, parte do grupo Saga Systems, continua a expandir suas fronteiras, buscando constantemente novas tecnologias e métodos para aprimorar a segurança e o conforto dos espaços. Estamos dedicados a construir um futuro mais seguro e conectado, onde cada detalhe é pensado para trazer paz de espírito aos nossos clientes. Junte-se a nós nesta jornada de inovação e descubra como a Saga Home pode transformar seu modo de viver e trabalhar.`,
        en_us: `Saga Home is proud to be at the forefront of innovation and security in the world of electronic locks. As part of the renowned Saga Systems group, our company has stood out in the market for offering advanced and personalized solutions in residential and commercial security systems. With an unwavering commitment to quality and customer satisfaction, Saga Home has revolutionized the way people interact with spaces, providing unprecedented peace of mind and convenience.
            <br>Our products are the result of extensive research and development, incorporating cutting-edge technologies that guarantee not only the safety of environments but also ease of use. From smart electronic locks that can be controlled by mobile devices to advanced biometric recognition systems, Saga Home is at the forefront of security innovation. Our diversified portfolio meets a wide range of needs, ensuring that every customer finds the perfect solution for their home or business.
            <br>Committed to excellence, Saga Home, part of the Saga Systems group, continues to expand its horizons, constantly seeking new technologies and methods to enhance the safety and comfort of spaces. We are dedicated to building a safer and more connected future, where every detail is designed to bring peace of mind to our customers. Join us on this journey of innovation and discover how Saga Home can transform your way of living and working.`,
        es_es: `Saga Home se enorgullece de estar a la vanguardia de la innovación y la seguridad en el mundo de las cerraduras electrónicas. Como parte del reconocido grupo Saga Systems, nuestra empresa se ha destacado en el mercado por ofrecer soluciones avanzadas y personalizadas en sistemas de seguridad residencial y comercial. Con un compromiso inquebrantable con la calidad y la satisfacción del cliente, Saga Home ha revolucionado la forma en que las personas interactúan con los espacios, proporcionando una tranquilidad y conveniencia sin precedentes.
            <br>Nuestros productos son el resultado de una extensa investigación y desarrollo, incorporando tecnologías de vanguardia que garantizan no solo la seguridad de los entornos, sino también la facilidad de uso. Desde cerraduras electrónicas inteligentes que se pueden controlar con dispositivos móviles hasta sistemas avanzados de reconocimiento biométrico, Saga Home está a la vanguardia de la innovación en seguridad. Nuestro portafolio diversificado satisface una amplia gama de necesidades, garantizando que cada cliente encuentre la solución perfecta para su hogar o negocio.
            <br>Comprometidos con la excelencia, Saga Home, parte del grupo Saga Systems, continúa expandiendo sus fronteras, buscando constantemente nuevas tecnologías y métodos para mejorar la seguridad y el confort de los espacios. Estamos dedicados a construir un futuro más seguro y conectado, donde cada detalle está diseñado para brindar tranquilidad a nuestros clientes. Únase a nosotros en este viaje de innovación y descubra cómo Saga Home puede transformar su forma de vivir y trabajar.`
    },

    aboutUsDescription: {
        pt_br: "Conheça um pouco sobre nossa história",
        en_us: "Learn a little about our history",
        es_es: "Conoce un poco de nuestra historia"
    },

    contactDescription: {
        pt_br: "Venha nos fazer uma visita",
        en_us: "Come visit us",
        es_es: "Venga a visitarnos"
    },

    contactFormDescription: {
        pt_br: `Estamos ansiosos para ouvir de você!
            <br>Entre em contato para esclarecer dúvidas, enviar reclamações ou compartilhar suas sugestões.
            <br>Sua opinião é muito importante para melhorarmos nossos produtos e serviços!`,
        en_us: `We are looking forward to hearing from you!
            <br>Get in touch to clarify questions, send complaints or share your suggestions.
            <br>Your opinion is very important for us to improve our products and services!`,
        es_es: `¡Esperamos tener noticias suyas!
            <br>Ponte en contacto para aclarar dudas, enviar quejas o compartir tus sugerencias.
            <br>¡Su opinión es muy importante para que podamos mejorar nuestros productos y servicios!`
    },

    contactFormErrorOnTrySend: {
        pt_br: "Ocorreu um problema ao tentar enviar o e-mail de contato...",
        en_us: "There was a problem trying to send the contact email...",
        es_es: "Hubo un problema al intentar enviar el correo electrónico de contacto..."
    },

    contactFormSentSuccessfully: {
        pt_br: "E-mail de contato enviado com sucesso!",
        en_us: "Contact email sent successfully!",
        es_es: "¡El correo electrónico de contacto se envió exitosamente!"
    },

    contactFormTitle: {
        pt_br: "Nosso contato",
        en_us: "Our Contact",
        es_es: "Nuestro contacto"
    },

    employeesDescription1: {
        pt_br: <>
            Venha ser um representante da {DefaultConstants.systemName}. 
            <br/>
            <span className="textalign-center cursor-pointer color-secondary fontsize-22 lineheight-24 display-flex alignitems-center justifycontent-center gap-8" onClick={() => history.push('/contact')}>
                <EmailOutlined className='fontsize-20 hide-on-md' />
                Entre em contato para maiores informações!
            </span>
        </>,
        en_us: <>
            Come be a representative of {DefaultConstants.systemName}. 
            <br/>
            <span className="textalign-center cursor-pointer color-secondary fontsize-22 lineheight-24 display-flex alignitems-center justifycontent-center gap-8" onClick={() => history.push('/contact')}>
                <EmailOutlined className='fontsize-20 hide-on-md' />
                Get in touch for more information!
            </span>
        </>,
        es_es: <>
            Ven a ser representante de {DefaultConstants.systemName}. 
            <br/>
            <span className="textalign-center cursor-pointer color-secondary fontsize-22 lineheight-24 display-flex alignitems-center justifycontent-center gap-8" onClick={() => history.push('/contact')}>
                <EmailOutlined className='fontsize-20 hide-on-md' />
                ¡Ponte en contacto para obtener más información!
            </span>
        </>
    },

    footerDescription1: {
        pt_br: "A Saga Systems Brasil oferece excelência em hospitalidade com produtos e serviços confiáveis, equipe competente e preços competitivos.",
        en_us: "Saga Systems Brazil offers excellence in hospitality with reliable products and services, a competent team, and competitive prices.",
        es_es: "Saga Systems Brasil ofrece excelencia en hospitalidad con productos y servicios confiables, un equipo competente y precios competitivos."
    },

    footerDescription2: {
        pt_br: "Desde 2009 criando uma hotelaria mais segura, com ética e transparência nos negócios.",
        en_us: "Since 2009, creating a safer hospitality environment with ethics and transparency in business.",
        es_es: "Desde 2009, creando una hotelería más segura, con ética y transparencia en los negocios."
    },

    homeWallpaper1Description: {
        pt_br: "Bem-vindo à Saga Systems",
        en_us: "Welcome to Saga Systems",
        es_es: "Bienvenido a Saga Systems"
    },

    homeWallpaper2Description: {
        pt_br: "Tecnologia, eficiência e segurança para o mercado hoteleiro do Brasil e do mundo!",
        en_us: "Technology, efficiency, and security for the hospitality market in Brazil and the world!",
        es_es: "¡Tecnología, eficiencia y seguridad para el mercado hotelero de Brasil y del mundo!"
    },

    homeWallpaper3Description: {
        pt_br: "A mais alta segurança em cofres digitais para hotéis, hospitais, escritórios e residências.<br>Um dos produtos mais premiados, perfeito para quem busca a comodidade de armazenar itens sem correr riscos, com o cofre mais difundido do mercado hoteleiro.",
        en_us: "The highest security in digital safes for hotels, hospitals, offices, and residences.<br>One of the most awarded products, perfect for those seeking the convenience of storing items without risks, with the most widely used safe in the hospitality market.",
        es_es: "La más alta seguridad en cajas fuertes digitales para hoteles, hospitales, oficinas y residencias.<br>Uno de los productos más premiados, perfecto para quienes buscan la comodidad de almacenar artículos sin correr riesgos, con la caja fuerte más difundida en el mercado hotelero."
    },

    homeWallpaper4Description: {
        pt_br: "Sustentabilidade, segurança em eletricidade e economia de energia para hotéis e hospitais.<br>Garanta economia de energia com um equipamento que tem o design e o luxo que combina com seu empreendimento, sem uso de cartões e fios!",
        en_us: "Sustainability, electrical safety, and energy savings for hotels and hospitals.<br>Ensure energy savings with equipment that has the design and luxury that matches your establishment, without the use of cards and wires!",
        es_es: "Sostenibilidad, seguridad eléctrica y ahorro de energía para hoteles y hospitales.<br>Asegure el ahorro de energía con un equipo que tiene el diseño y el lujo que combina con su establecimiento, ¡sin el uso de tarjetas ni cables!"
    },

    productsDescription: {
        pt_br: "As fechaduras eletrônicas da Saga Systems representam inovação e segurança para o mercado da hospitalidade. Projetadas para atender às exigências deste setor, essas fechaduras oferecem conveniência e eficiência, aumentando a satisfação dos hóspedes e melhorando a gestão dos estabelecimentos. Com tecnologia de ponta, elas permitem acesso sem chave, através de cartões de aproximação (RDFI) e também por smartphones, garantindo uma experiência de check-in rápida e sem complicações. Além disso, o sistema de auditoria em tempo real proporciona aos gestores total controle e segurança sobre os acessos. A Saga Systems se destaca por sua confiabilidade e facilidade de integração, tornando-se a escolha ideal para hotéis que buscam modernidade e segurança.",
        en_us: "Saga Systems' electronic locks represent innovation and security for the hospitality market. Designed to meet the demands of this sector, these locks offer convenience and efficiency, enhancing guest satisfaction and improving establishment management. With cutting-edge technology, they allow keyless access through proximity cards (RDFI) and also via smartphones, ensuring a quick and hassle-free check-in experience. Moreover, the real-time auditing system provides managers with total control and security over access. Saga Systems stands out for its reliability and ease of integration, making it the ideal choice for hotels seeking modernity and security.",
        es_es: "Las cerraduras electrónicas de Saga Systems representan innovación y seguridad para el mercado de la hospitalidad. Diseñadas para satisfacer las exigencias de este sector, estas cerraduras ofrecen conveniencia y eficiencia, aumentando la satisfacción de los huéspedes y mejorando la gestión de los establecimientos. Con tecnología de punta, permiten acceso sin llave, a través de tarjetas de proximidad (RDFI) y también mediante teléfonos inteligentes, garantizando una experiencia de check-in rápida y sin complicaciones. Además, el sistema de auditoría en tiempo real proporciona a los gerentes control total y seguridad sobre los accesos. Saga Systems se destaca por su confiabilidad y facilidad de integración, convirtiéndose en la elección ideal para hoteles que buscan modernidad y seguridad."
    },

    productsSubtitle: {
        pt_br: "Segurança e tecnologia juntos",
        en_us: "Security and technology together",
        es_es: "Seguridad y tecnología juntas"
    },

    protectingYourHomeWithTechnology: {
        pt_br: "Protegendo seu lar com tecnologia",
        en_us: "Protecting your home with technology",
        es_es: "Protegiendo tu hogar con tecnología"
    },

    servicesDescription: {
        pt_br: "A Saga Systems destaca-se no mercado por oferecer uma gama abrangente de serviços que visam garantir a eficiência e o bom funcionamento dos sistemas de nossos clientes. Além da reestruturação de sistemas, nossos serviços incluem treinamentos personalizados e programas de reciclagem para capacitar as equipes a maximizar o uso das tecnologias. Oferecemos também manutenções preventivas e corretivas, assegurando que os sistemas operem de maneira otimizada e que interrupções sejam minimizadas. Nossa missão é proporcionar soluções que contribuam para o sucesso contínuo de nossos clientes, através de um suporte técnico especializado e adaptado às suas necessidades específicas.",
        en_us: "Saga Systems stands out in the market by offering a comprehensive range of services aimed at ensuring the efficiency and proper functioning of our clients' systems. In addition to system restructuring, our services include personalized training and recycling programs to empower teams to maximize the use of technologies. We also provide preventive and corrective maintenance, ensuring that systems operate optimally and minimizing disruptions. Our mission is to provide solutions that contribute to the ongoing success of our clients through specialized technical support tailored to their specific needs.",
        es_es: "Saga Systems se destaca en el mercado por ofrecer una amplia gama de servicios destinados a garantizar la eficiencia y el buen funcionamiento de los sistemas de nuestros clientes. Además de la reestructuración de sistemas, nuestros servicios incluyen capacitación personalizada y programas de reciclaje para empoderar a los equipos a maximizar el uso de las tecnologías. También ofrecemos mantenimiento preventivo y correctivo, asegurando que los sistemas funcionen de manera óptima y minimizando las interrupciones. Nuestra misión es proporcionar soluciones que contribuyan al éxito continuo de nuestros clientes, a través de un soporte técnico especializado y adaptado a sus necesidades específicas."
    },
    
    servicesSubtitle: {
        pt_br: "Qualidade e excelência que sua empresa precisa",
        en_us: "Quality and excellence your company needs",
        es_es: "Calidad y excelencia que su empresa necesita"
    },
}

export default WebsiteTranslation;