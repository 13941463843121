
const ProductsTranslation = {
    accessories: {
        pt_br: `Acessórios`,
        en_us: `Accessories`,
        es_es: `Accesorios`
    },

    accessoriesDescription: {
        pt_br: `Os acessórios Saga Systems são essenciais para otimizar o desempenho dos equipamentos, melhorar a eficiência operacional e garantir a durabilidade dos sistemas`,
        en_us: `Saga Systems accessories are essential to optimize equipment performance, improve operational efficiency, and ensure system durability.`,
        es_es: `Los accesorios de Saga Systems son esenciales para optimizar el rendimiento del equipo, mejorar la eficiencia operativa y garantizar la durabilidad de los sistemas.`
    },

    accessoriesHomeDescription: {
        pt_br: `Acessórios indispensáveis e inteligentes, melhoram a segurança`,
        en_us: `Indispensable and smart accessories improve safety.`,
        es_es: `Accesorios indispensables e inteligentes, mejoran la seguridad.`
    },

    accessory1: {
        pt_br: `Cartões RFID`,
        en_us: `RFID Cards`,
        es_es: `Tarjetas RFID`
    },

    accessory1Description: {
        pt_br: <>
            <span>Cartões de rádio frequência MIFARE, padrão SAGA ou personalizado com a arte de seu hotel, disponíveis em PVC ou em Madeira Reciclável (cartão ecológico)</span>
            <br/> <br/>

            <ul>
                <li>Faixa de operação: 13.56 MHz, ISO 14443A</li>
                <li>Retenção de dados por mais de 10 anos</li>
                <li>Sem problemas de "desmagnetização"</li>
                <li>Dimensões: 5.4 x 8.6 cm</li>
                <li>Todos os modelos são Impermeáveis</li>
            </ul>
        </>,
        en_us: <>
            <span>MIFARE radio frequency cards, SAGA standard or customized with your hotel’s artwork, available in PVC or Recyclable Wood (eco-friendly card).</span>
            <br/> <br/>

            <ul>
                <li>Operating range: 13.56 MHz, ISO 14443A</li>
                <li>Data retention for over 10 years</li>
                <li>No "demagnetization" issues</li>
                <li>Dimensions: 5.4 x 8.6 cm</li>
                <li>All models are Waterproof</li>
            </ul>
        </>,
        es_es: <>
            <span>Tarjetas de frecuencia de radio MIFARE, estándar SAGA o personalizadas con el arte de su hotel, disponibles en PVC o en Madera Reciclable (tarjeta ecológica).</span>
            <br/> <br/>

            <ul>
                <li>Rango de operación: 13.56 MHz, ISO 14443A</li>
                <li>Retención de datos por más de 10 años</li>
                <li>Sin problemas de "desmagnetización"</li>
                <li>Dimensiones: 5.4 x 8.6 cm</li>
                <li>Todos los modelos son Impermeables</li>
            </ul>
        </>
    },

    accessory2: {
        pt_br: `Fecho de segurança`,
        en_us: `Security clasp`,
        es_es: `Cierre de seguridad`
    },

    accessory2Description: {
        pt_br: <>
            <span>O Fecho de Segurança, popularmente conhecido como "Pega-Ladrão", é um item de segurança que limita a abertura da porta.</span>
            <br/> <br/>

            <span>Além de permitir que o hóspede abra parcialmente a porta para coletar alguma encomenda ou documento, o fecho de segurança também atua impedindo que, por exemplo, crianças pequenas deixem a acomodação.</span>
            <br/> <br/>

            <span>Disponível nas versões:</span>
            <br/> <br/>

            <span>
                <b>Luxo:</b> Fecho de Segurança Corrente Luxo em Aço Forjado e Liga de Zinco.
            </span>
            <br/> <br/>

            <span>
                <b>Básico:</b> Fecho Trifor em Zamak Cromado com calço em ABS.
            </span>
        </>,
        en_us: <>
            <span>The Security Clasp, commonly known as "Thief Catcher," is a security item that limits door opening.</span>
            <br/> <br/>

            <span>In addition to allowing the guest to partially open the door to collect an order or document, the security clasp also prevents, for example, small children from leaving the accommodation.</span>
            <br/> <br/>

            <span>Available in the following versions:</span>
            <br/> <br/>

            <span>
                <b>Luxury:</b> Luxury Chain Security Clasp in Forged Steel and Zinc Alloy.
            </span>
            <br/> <br/>

            <span>
                <b>Basic:</b> Trifor Clasp in Chrome Zamak with ABS wedge.
            </span>
        </>,
        es_es: <>
            <span>El Cierre de Seguridad, comúnmente conocido como "Atrapa Ladrones", es un elemento de seguridad que limita la apertura de la puerta.</span>
            <br/> <br/>

            <span>Además de permitir que el huésped abra parcialmente la puerta para recoger un pedido o documento, el cierre de seguridad también evita que, por ejemplo, los niños pequeños abandonen la habitación.</span>
            <br/> <br/>

            <span>Disponible en las siguientes versiones:</span>
            <br/> <br/>

            <span>
                <b>Lujo:</b> Cierre de Seguridad de Cadena de Lujo en Acero Forjado y Aleación de Zinc.
            </span>
            <br/> <br/>

            <span>
                <b>Básico:</b> Cierre Trifor en Zamak Cromado con cuña en ABS.
            </span>
        </>
    },

    accessory3: {
        pt_br: `Prendedor de porta`,
        en_us: `Door Holder`,
        es_es: `Sujeción de puerta`
    },

    accessory3Description: {
        pt_br: <>
            <span>O prendedor magnético de porta, tem função de manter a porta aberta e evitar que correntes de ar fechem a porta de repente ou fique batendo.</span>
            <br/> <br/>

            <span>
                <b>Dimensões:</b> 8,5 cm (altura) x 4,8 cm (diâmetro)
            </span>
            <br/>

            <span>
                <b>Material:</b> Aço Inox escovado
            </span>
            <br/>

            <span>
                <b>Peso (kg):</b> 0,132kg
            </span>
            <br/>

            <span>
                <b>Instalação:</b> parafusado no piso ou na parede
            </span>
            <br/>

            <span>
                <b>Indicação:</b> portas comerciais e residenciais
            </span>
        </>,
        en_us: <>
            <span>The magnetic door holder keeps the door open and prevents drafts from closing the door suddenly or banging it shut.</span>
            <br/> <br/>

            <span>
                <b>Dimensions:</b> 8.5 cm (height) x 4.8 cm (diameter)
            </span>
            <br/>

            <span>
                <b>Material:</b> Brushed Stainless Steel
            </span>
            <br/>

            <span>
                <b>Weight (kg):</b> 0.132kg
            </span>
            <br/>

            <span>
                <b>Installation:</b> screwed into the floor or wall
            </span>
            <br/>

            <span>
                <b>Recommended for:</b> commercial and residential doors
            </span>
        </>,
        es_es: <>
            <span>El sujetador magnético de puerta tiene la función de mantener la puerta abierta y evitar que corrientes de aire cierren la puerta de repente o la hagan golpear.</span>
            <br/> <br/>

            <span>
                <b>Dimensiones:</b> 8,5 cm (altura) x 4,8 cm (diámetro)
            </span>
            <br/>

            <span>
                <b>Material:</b> Acero Inoxidable Cepillado
            </span>
            <br/>

            <span>
                <b>Peso (kg):</b> 0,132kg
            </span>
            <br/>

            <span>
                <b>Instalación:</b> atornillado al suelo o a la pared
            </span>
            <br/>

            <span>
                <b>Indicación:</b> puertas comerciales y residenciales
            </span>
        </>
    },

    accessory4: {
        pt_br: `Olho mágico`,
        en_us: `Peephole`,
        es_es: `Mirilla`
    },

    accessory4Description: {
        pt_br: <>
            <span>O Olho Mágico é um dispositivo de segurança que permite que o hóspede verifique quem se encontra do lado externo de sua acomodação, antes de abrir a porta.</span>
            <br/> <br/>

            <span>
                <b>Material:</b> Aço Inox
            </span>
            <br/>

            <span>
                <b>Dimensões:</b> 5,6 cm de diâmetro
            </span>
        </>,
        en_us: <>
            <span>The Peephole is a security device that allows guests to check who is outside their accommodation before opening the door.</span>
            <br/> <br/>

            <span>
                <b>Material:</b> Stainless Steel
            </span>
            <br/>

            <span>
                <b>Dimensions:</b> 5.6 cm in diameter
            </span>
        </>,
        es_es: <>
            <span>La Mirilla es un dispositivo de seguridad que permite al huésped verificar quién está afuera de su alojamiento antes de abrir la puerta.</span>
            <br/> <br/>

            <span>
                <b>Material:</b> Acero Inoxidable
            </span>
            <br/>

            <span>
                <b>Dimensiones:</b> 5,6 cm de diámetro
            </span>
        </>
    },

    accessory5: {
        pt_br: `Pulseiras`,
        en_us: `Bracelets`,
        es_es: `Pulseras`
    },

    accessory5Description: {
        pt_br: <>
            <span>A pulseira tem o objetivo de alertar sobre o uso do cartão de acesso do hóspede, evitando que a porta seja aberta por engano.</span>
            <br/> <br/>

            <span>
                <b>Material:</b> Aço Inox
            </span>
            <br/>

            <span>
                <b>Dimensões:</b> 7,5 cm x 3 cm
            </span>
        </>,
        en_us: <>
            <span>The bracelets is designed to alert about the use of the guest access card, preventing the door from being mistakenly opened.</span>
            <br/> <br/>

            <span>
                <b>Material:</b> Stainless Steel
            </span>
            <br/>

            <span>
                <b>Dimensions:</b> 7.5 cm x 3 cm
            </span>
        </>,
        es_es: <>
            <span>El pulseras tiene el objetivo de alertar sobre el uso de la tarjeta de acceso del huésped, evitando que la puerta sea abierta por error.</span>
            <br/> <br/>

            <span>
                <b>Material:</b> Acero Inoxidable
            </span>
            <br/>

            <span>
                <b>Dimensiones:</b> 7,5 cm x 3 cm
            </span>
        </>
    },

    accessory6: {
        pt_br: `Gravador de cartões`,
        en_us: `Signage`,
        es_es: `Escritor de tarjetas`
    },

    accessory6Description: {
        pt_br: <>
            <span>O gravador de cartões oferece informações e orientações sobre as regras e regulamentos do local, garantindo a segurança de todos.</span>
            <br/> <br/>

            <span>
                <b>Material:</b> Alumínio
            </span>
            <br/>

            <span>
                <b>Dimensões:</b> 15 cm x 10 cm
            </span>
        </>,
        en_us: <>
            <span>The signage provides information and guidance on the rules and regulations of the location, ensuring everyone's safety.</span>
            <br/> <br/>

            <span>
                <b>Material:</b> Aluminum
            </span>
            <br/>

            <span>
                <b>Dimensions:</b> 15 cm x 10 cm
            </span>
        </>,
        es_es: <>
            <span>Lo escritor de tarjetas ofrece información y orientación sobre las reglas y regulaciones del lugar, garantizando la seguridad de todos.</span>
            <br/> <br/>

            <span>
                <b>Material:</b> Aluminio
            </span>
            <br/>

            <span>
                <b>Dimensiones:</b> 15 cm x 10 cm
            </span>
        </>
    },

    accessory7: {
        pt_br: `Dobradiças`,
        en_us: `Hinges`,
        es_es: `Bisagras`
    },

    accessory7Description: {
        pt_br: <>
            <span>Dobradiça com mola interna para fechamento automático de portas comuns.</span>
            <br/> <br/>

            <span>
                <b>Aplicação:</b> portas de madeira ou metálicas
            </span>
            <br/>

            <span>
                <b>Material:</b> Aço Inox 304
            </span>
            <br/>

            <span>
                <b>Dimensões:</b> 3" x 4"
            </span>
            <br/>

            <span>
                <b>Espessura:</b> 0.7 mm
            </span>
            <br/> <br/>

            <span>Fixação Reversível</span>
            <br/>

            <span>Visual limpo e discreto</span>
            <br/>

            <span>Inclui parafusos de fixação</span>
        </>,
        en_us: <>
            <span>Hinge with internal spring for automatic closing of common doors.</span>
            <br/> <br/>

            <span>
                <b>Application:</b> wooden or metal doors
            </span>
            <br/>

            <span>
                <b>Material:</b> Stainless Steel 304
            </span>
            <br/>

            <span>
                <b>Dimensions:</b> 3" x 4"
            </span>
            <br/>

            <span>
                <b>Thickness:</b> 0.7 mm
            </span>
            <br/> <br/>

            <span>Reversible fastening</span>
            <br/>

            <span>Clean and discreet appearance</span>
            <br/>

            <span>Includes fastening screws</span>
        </>,
        es_es: <>
            <span>Bisagra con resorte interno para el cierre automático de puertas comunes.</span>
            <br/> <br/>

            <span>
                <b>Aplicación:</b> puertas de madera o metálicas
            </span>
            <br/>

            <span>
                <b>Material:</b> Acero Inoxidable 304
            </span>
            <br/>

            <span>
                <b>Dimensiones:</b> 3" x 4"
            </span>
            <br/>

            <span>
                <b>Espesor:</b> 0.7 mm
            </span>
            <br/> <br/>

            <span>Fijación reversible</span>
            <br/>

            <span>Apariencia limpia y discreta</span>
            <br/>

            <span>Incluye tornillos de fijación</span>
        </>
    },

    accessory8: {
        pt_br: `Cartões chaveiros`,
        en_us: `Key fobs`,
        es_es: `Llaveros`
    },

    accessory8Description: {
        pt_br: <>
            <span>Ideal para ser utilizado no economizador de energia quando o empreendimento possuir chaves mecânicas nas portas dos apartamentos.</span>
        </>,
        en_us: <>
            <span>Ideal for use in energy savers when the establishment has mechanical keys on the apartment doors.</span>
        </>,
        es_es: <>
            <span>Ideal para su uso en ahorradores de energía cuando el establecimiento tenga llaves mecánicas en las puertas de los apartamentos.</span>
        </>
    },

    accessory9: {
        pt_br: `Barra antipânico`,
        en_us: `Panic bar`,
        es_es: `Barra antipánico`
    },

    accessory9Description: {
        pt_br: <>
            <ul>
                <li>Barras Anti-Pânico conforme a NBR 13.768 (acessórios destinados à porta corta fogo para saída de emergência), confeccionada em metal reforçado;</li>
                <li>Para portas de uma folha (simples) ou de duas folhas (portas duplas);</li>
                <li>Utilização em salas de evento, auditórios e saídas de emergência.</li>
                <li>Integração com fechadura eletrônica de controle de acesso, permitindo controle das entradas por horário e por usuário. O responsável pela sala, por exemplo, teria autorização de fechar e abrir a porta quantas vezes for necessário.</li>
                <li>Sistema de auditoria que informa quando a fechadura foi aberta, e por quem.</li>
            </ul>

            <br/>
            <span>
                <b>Componentes:</b> Barra de acionamento, tubo de travamento do cremone, trinco e componentes internos.
            </span>
        </>,
        en_us: <>
            <ul>
                <li>Panic bars according to NBR 13.768 (accessories for fire doors for emergency exit), made of reinforced metal;</li>
                <li>For single leaf (simple) or double leaf (double doors);</li>
                <li>Used in event halls, auditoriums, and emergency exits.</li>
                <li>Integration with electronic access control locks, allowing entry control by schedule and user. The person responsible for the room would have permission to open and close the door as needed.</li>
                <li>Auditing system that informs when the lock was opened and by whom.</li>
            </ul>

            <br/>
            <span>
                <b>Components:</b> Activation bar, locking tube, latch, and internal components.
            </span>
        </>,
        es_es: <>
            <ul>
                <li>Barras Anti-Pánico según la NBR 13.768 (accesorios para puertas corta fuego para salida de emergencia), fabricadas en metal reforzado;</li>
                <li>Para puertas de una hoja (simples) o de dos hojas (puertas dobles);</li>
                <li>Uso en salas de eventos, auditorios y salidas de emergencia.</li>
                <li>Integración con cerradura electrónica de control de acceso, permitiendo el control de las entradas por horario y por usuario. El responsable de la sala tendría autorización para cerrar y abrir la puerta cuantas veces sea necesario.</li>
                <li>Sistema de auditoría que informa cuándo se abrió la cerradura y por quién.</li>
            </ul>

            <br/>
            <span>
                <b>Componentes:</b> Barra de activación, tubo de bloqueo, pestillo y componentes internos.
            </span>
        </>
    },

    accessory10: {
        pt_br: `Leitor de rádio-frequência`,
        en_us: `Radio frequency reader`,
        es_es: `Lector de radiofrecuencia`
    },

    accessory10Description: {
        pt_br: <>
            <span>Leitor de Cartões de rádio frequência padrão MIFARE.</span>
            <br/> <br/>

            <span>Ideal para mecanismos de abertura com eletroimã, portões, cancelas e totens.</span>
            <br/> <br/>

            <span>Compatível com todos os acessórios de rádio frequência da Saga, suportando cartões inteligentes em conformidade com as normas ISO 14443A e ISO 14443B.</span>
            <br/> <br/>

            <span>O controle pode ser instalado para o controle de acesso da entrada de estacionamentos, e a catraca poderá controlar as entradas e saídas dos funcionários e clientes.</span>
            <br/> <br/>

            <span> <b>Características Técnicas Cancela Garagem:</b> </span>
            <ul>
                <li>Cor da Cancela: Vermelha</li>
                <li>110V e 220V</li>
                <li>Tempo para a cancela baixar: 2s (para 1 a 3m), 6s (para 4 a 6m)</li>
                <li>Contém dois transmissores para o controle remoto de até 25m de distância.</li>
            </ul>
            <br/>

            <span> <b>Características Técnicas Catraca:</b> </span>
            <ul>
                <li>Controle de Acesso através de Software</li>
                <li>Quantidade de Usuários: Ilimitado</li>
            </ul>
            <br/>

            <span> <b>Características Técnicas Leitor Mural (portas com eletroimã, portas e portões automáticos):</b> </span>
            <ul>
                <li>Voltagem de entrada: AC 100/240V</li>
                <li>Voltagem de trabalho: DC 12V</li>
                <li>Padrão de Leitura: Itens de Radiofrequência Mifare</li>
                <li>Umidade de trabalho: 10% ~ 93%</li>
                <li>Pode acionar qualquer tipo de fechadura eletromagnética</li>
            </ul>
        </>,
        en_us: <>
            <span>MIFARE standard radio frequency card reader.</span>
            <br/> <br/>

            <span>Ideal for opening mechanisms with electromagnetic locks, gates, barriers, and totems.</span>
            <br/> <br/>

            <span>Compatible with all Saga radio frequency accessories, supporting smart cards in compliance with ISO 14443A and ISO 14443B standards.</span>
            <br/> <br/>

            <span>The control can be installed for parking lot access control, and the turnstile can manage the entries and exits of employees and clients.</span>
            <br/> <br/>

            <span> <b>Garage Barrier Technical Characteristics:</b> </span>
            <ul>
                <li>Barrier Color: Red</li>
                <li>110V and 220V</li>
                <li>Time for the barrier to drop: 2s (for 1 to 3m), 6s (for 4 to 6m)</li>
                <li>Includes two transmitters for remote control up to 25m away.</li>
            </ul>
            <br/>

            <span> <b>Turnstile Technical Characteristics:</b> </span>
            <ul>
                <li>Access Control via Software</li>
                <li>Number of Users: Unlimited</li>
            </ul>
            <br/>

            <span> <b>Wall Reader Technical Characteristics (doors with electromagnetic locks, automatic doors and gates):</b> </span>
            <ul>
                <li>Input Voltage: AC 100/240V</li>
                <li>Operating Voltage: DC 12V</li>
                <li>Reading Standard: Mifare Radio Frequency Items</li>
                <li>Operating Humidity: 10% ~ 93%</li>
                <li>Can trigger any type of electromagnetic lock</li>
            </ul>
        </>,
        es_es: <>
            <span>lector de tarjetas de radiofrecuencia estándar MIFARE.</span>
            <br/> <br/>

            <span>Ideal para mecanismos de apertura con electroimán, portones, barreras y tótems.</span>
            <br/> <br/>

            <span>Compatible con todos los accesorios de radiofrecuencia de Saga, soportando tarjetas inteligentes en conformidad con las normas ISO 14443A y ISO 14443B.</span>
            <br/> <br/>

            <span>El control se puede instalar para el control de acceso de la entrada de estacionamientos, y la torniquete podrá controlar las entradas y salidas de los empleados y clientes.</span>
            <br/> <br/>

            <span> <b>Características Técnicas Barrera de Garaje:</b> </span>
            <ul>
                <li>Color de la Barrera: Roja</li>
                <li>110V y 220V</li>
                <li>Tiempo para que la barrera baje: 2s (para 1 a 3m), 6s (para 4 a 6m)</li>
                <li>Contiene dos transmisores para el control remoto de hasta 25m de distancia.</li>
            </ul>
            <br/>

            <span> <b>Características Técnicas Torniquete:</b> </span>
            <ul>
                <li>Control de Acceso a través de Software</li>
                <li>Cantidad de Usuarios: Ilimitada</li>
            </ul>
            <br/>

            <span> <b>Características Técnicas Lector Mural (puertas con electroimán, puertas y portones automáticos):</b> </span>
            <ul>
                <li>Voltaje de entrada: AC 100/240V</li>
                <li>Voltaje de trabajo: DC 12V</li>
                <li>Estándar de Lectura: Artículos de Radiofrecuencia Mifare</li>
                <li>Humedad de trabajo: 10% ~ 93%</li>
                <li>Puede accionar cualquier tipo de cerradura electromagnética</li>
            </ul>
        </>
    },

    accessory11: {
        pt_br: `Leitor de elevador`,
        en_us: `Elevator reader`,
        es_es: `Lector de ascensor`
    },

    accessory11Description: {
        pt_br: <>
            <span>Controle de Acesso para Elevador</span>
            <br/> <br/>

            <ul>
                <li>Leitor de cartões Mifare que trabalha conectado a uma placa de relês. Provido de sinais luminosos para informar ao usuário sobre o resultado da leitura e validade do cartão. Lê cartões de proximidade (Mifare).</li>
                <li>Quando o leitor realiza a leitura do cartão, verifica as informações como a data de validade, hora e código do cartão. Se estes parâmetros estão corretos, lê as autorizações dadas aos cartões e ativa os relês correspondentes. Se o cartão não é válido, o leitor não ativará nenhum relê e indicará led vermelho.</li>
            </ul>
        </>,
        en_us: <>
            <span>Access Control for Elevator</span>
            <br/> <br/>

            <ul>
                <li>Mifare card reader that works connected to a relay board. Equipped with lights to inform the user about the reading result and card validity. Reads proximity cards (Mifare).</li>
                <li>When the reader reads the card, it checks information such as expiration date, time, and card code. If these parameters are correct, it reads the authorizations given to the cards and activates the corresponding relays. If the card is not valid, the reader will not activate any relay and will indicate a red light.</li>
            </ul>
        </>,
        es_es: <>
            <span>Control de Acceso para Ascensor</span>
            <br/> <br/>

            <ul>
                <li>lector de tarjetas Mifare que trabaja conectado a una placa de relés. Proporcionado con señales luminosas para informar al usuario sobre el resultado de la lectura y la validez de la tarjeta. Lee tarjetas de proximidad (Mifare).</li>
                <li>Cuando el lector realiza la lectura de la tarjeta, verifica información como la fecha de vencimiento, la hora y el código de la tarjeta. Si estos parámetros son correctos, lee las autorizaciones dadas a las tarjetas y activa los relés correspondientes. Si la tarjeta no es válida, el lector no activará ningún relé y indicará led rojo.</li>
            </ul>
        </>
    },

    accessory12: {
        pt_br: `Mola aérea`,
        en_us: `Overhead spring`,
        es_es: `Muelle aéreo`
    },

    accessory12Description: {
        pt_br: <>
            <ul>
                <li>Mola Aérea, sem braço de parada, com duas válvulas para regulagem e controle da velocidade de fechamento da porta. Aconselhável para portas de Madeira, Alumínio e Vidro (para vidros, é necessário o uso de suporte para fixação).</li>
                <li>Potência 2/3/4, para portas de 850 até 1100mm de largura. É necessário o deslocamento da mola no ato da instalação para regular a potência. (Conforme gabarito que vem junto com a mola).</li>
                <li>Atenção: Não é aconselhável o uso da mola aérea em portas de vidro na posição da potência 2.</li>
            </ul>

            <br/>
            <span>
                <b>Potência 2:</b> Portas até 850mm 25-45 kg
            </span>

            <br/>
            <span>
                <b>Potência 3:</b> Portas até 950mm 40-65kg
            </span>

            <br/>
            <span>
                <b>Potência 4:</b> Portas até 1100mm 60-80kg
            </span>
        </>,
        en_us: <>
            <ul>
                <li>Overhead spring, without a stop arm, with two valves for adjusting and controlling the door closing speed. Recommended for Wooden, Aluminum, and Glass doors (for glass, a mounting support is required).</li>
                <li>Power 2/3/4, for doors 850 to 1100mm wide. It is necessary to move the spring during installation to adjust the power (according to the template that comes with the spring).</li>
                <li>Note: It is not advisable to use the overhead spring on glass doors in power position 2.</li>
            </ul>

            <br/>
            <span>
                <b>Power 2:</b> Doors up to 850mm 25-45 kg
            </span>

            <br/>
            <span>
                <b>Power 3:</b> Doors up to 950mm 40-65 kg
            </span>

            <br/>
            <span>
                <b>Power 4:</b> Doors up to 1100mm 60-80 kg
            </span>
        </>,
        es_es: <>
            <ul>
                <li>Muelle aéreo, sin brazo de parada, con dos válvulas para el ajuste y control de la velocidad de cierre de la puerta. Aconsejable para puertas de Madera, Aluminio y Vidrio (para vidrios, es necesario el uso de soporte para fijación).</li>
                <li>Potencia 2/3/4, para puertas de 850 hasta 1100mm de ancho. Es necesario el desplazamiento del muelle en el momento de la instalación para regular la potencia. (Según la plantilla que viene junto con el muelle).</li>
                <li>Atención: No es aconsejable el uso del muelle aéreo en puertas de vidrio en la posición de potencia 2.</li>
            </ul>

            <br/>
            <span>
                <b>Potencia 2:</b> Puertas hasta 850mm 25-45 kg
            </span>

            <br/>
            <span>
                <b>Potencia 3:</b> Puertas hasta 950mm 40-65 kg
            </span>

            <br/>
            <span>
                <b>Potencia 4:</b> Puertas hasta 1100mm 60-80 kg
            </span>
        </>
    },

    electronicLocks: {
        pt_br: `Fechaduras eletrônicas`,
        en_us: `Electronic locks`,
        es_es: `Cerraduras electrónicas`
    },

    electronicLock1: {
        pt_br: `Elegante Slim`,
        en_us: `Elegant Slim`,
        es_es: `Elegante Slim`
    },

    electronicLock1Description: {
        pt_br: <>
            <span>Fechadura eletrônica de alto padrão para hotelaria</span>
            <br/>

            <ul>
                <li>Tecnologia Mifare (de proximidade)</li>
                <li>Mais segurança</li>
                <li>Design clean, adaptável a qualquer tipo de decoração</li>
                <li>Resistente ao fogo</li>
                <li>Facilidade de uso em casos de emergência</li>
            </ul>
            <br/>

            <span> <b>Especificações gerais:</b> </span>
            <ul>
                <li>Abertura com celular utilizando Bluetooth de Baixo Consumo (BLE).</li>
                <li>Sistema anti pânico.</li>
                <li>Certificada nas normas internacionais de segurança contra propagação do fogo CE e RF.</li>
                <li>Funcionamento com 4 pilhas AA, durando em média 2 anos.</li>
                <li>Em aço inoxidável AISI304.</li>
                <li>Integração com software de gestão hoteleira.</li>
                <li>Circuito eletrônico anti-humidade adaptável a qualquer tipo de clima.</li>
                <li>Sistema de memória não volátil EPROM que garante a manutenção das informações de acesso mesmo havendo troca de pilhas.</li>
                <li>Abertura de emergência através de chave mecânica.</li>
                <li>Disponível em versão para Porta de Correr ou com máquina especial para portas com perfil estreito (entre em contato para mais informações).</li>
                <li>Aviso sonoro e visual de baterias fracas.</li>
                <li>Especificações da mecânica: Máquina EURO 22 mm com backset de 65 mm. Trinco de segurança maciço.</li>
                <li>Auditoria das últimas 400 aberturas, seja por chave mecânica ou por cartão, indicando Data; Hora; Nome de quem acessou (no caso de funcionários); Quem fez o cartão de acesso e quando fez o cartão</li>
                <li>Aplicabilidade: portas de 35-80 mm.</li>
            </ul>
            <br/>

            <span>
                <a href='https://3dwarehouse.sketchup.com/model/87f79b1d-dcf9-47f9-b6ca-c7969aadc9f7/Saga-Systems-BIM-Modelo-Fechadura-Elegante-Hotel' target="_blank" rel="noreferrer">Clique aqui</a> 
                {' '}
                para ver nosso Modelo em 3D para BIM!
            </span>
            <br/> <br/>

            <span> 
                Quer melhorar a segurança em seu hotel? Leia nosso artigo 
                {' '}
                <a href='https://saga.greenmake.com.br/blog/2020/08/05/fechaduras-para-hotel/' target="_blank" rel="noreferrer">clicando aqui</a>!
            </span>
            <br/> <br/>

            <span> 
                <b>Atenção:</b> 
                {' '}
                Os acabamentos Golden, Bronze e Preto Matte são feitos por encomenda.
            </span>
            <br/>

            <span>Favor verificar a disponibilidade com nossa equipe!</span>
        </>,
        en_us: <>
            <span>High-end electronic lock for hospitality</span>
            <br/>

            <ul>
                <li>Mifare (proximity) technology</li>
                <li>Increased security</li>
                <li>Clean design, adaptable to any type of decor</li>
                <li>Fire-resistant</li>
                <li>Ease of use in emergencies</li>
            </ul>
            <br/>

            <span> <b>General specifications:</b> </span>
            <ul>
                <li>Opening with mobile phone using Low Energy Bluetooth (BLE).</li>
                <li>Anti-panic system.</li>
                <li>Certified under international fire spread safety standards CE and RF.</li>
                <li>Operates on 4 AA batteries, lasting an average of 2 years.</li>
                <li>Made of AISI304 stainless steel.</li>
                <li>Integration with hotel management software.</li>
                <li>Humidity-resistant electronic circuit adaptable to any climate.</li>
                <li>Non-volatile EPROM memory system ensuring access information is maintained even when batteries are replaced.</li>
                <li>Emergency opening with mechanical key.</li>
                <li>Available in a sliding door version or with a special machine for narrow-profile doors (contact us for more information).</li>
                <li>Audible and visual alert for low batteries.</li>
                <li>Mechanical specifications: EURO machine 22 mm with 65 mm backset. Solid security latch.</li>
                <li>Audit of the last 400 openings, whether by mechanical key or card, indicating Date; Time; Name of the person who accessed (in the case of staff); Who created the access card and when the card was created</li>
                <li>Applicability: doors of 35-80 mm.</li>
            </ul>
            <br/>

            <span>
                <a href='https://3dwarehouse.sketchup.com/model/87f79b1d-dcf9-47f9-b6ca-c7969aadc9f7/Saga-Systems-BIM-Modelo-Fechadura-Elegante-Hotel' target="_blank" rel="noreferrer">Click here</a> 
                {' '}
                to view our 3D Model for BIM!
            </span>
            <br/> <br/>

            <span> 
                Want to improve security in your hotel? Read our article 
                {' '}
                <a href='https://saga.greenmake.com.br/blog/2020/08/05/fechaduras-para-hotel/' target="_blank" rel="noreferrer">by clicking here</a>!
            </span>
            <br/> <br/>

            <span> 
                <b>Attention:</b> 
                {' '}
                The Golden, Bronze, and Matte Black finishes are made to order.
            </span>
            <br/>

            <span>Please check availability with our team!</span>
        </>,
        es_es: <>
            <span>Cerradura electrónica de alta gama para hotelería</span>
            <br/>

            <ul>
                <li>Tecnología Mifare (de proximidad)</li>
                <li>Mayor seguridad</li>
                <li>Diseño limpio, adaptable a cualquier tipo de decoración</li>
                <li>Resistente al fuego</li>
                <li>Facilidad de uso en casos de emergencia</li>
            </ul>
            <br/>

            <span> <b>Especificaciones generales:</b> </span>
            <ul>
                <li>Apertura con celular utilizando Bluetooth de Bajo Consumo (BLE).</li>
                <li>Sistema anti pánico.</li>
                <li>Certificada en las normas internacionales de seguridad contra propagación del fuego CE y RF.</li>
                <li>Funciona con 4 pilas AA, durando en promedio 2 años.</li>
                <li>En acero inoxidable AISI304.</li>
                <li>Integración con software de gestión hotelera.</li>
                <li>Circuito electrónico anti-humedad adaptable a cualquier tipo de clima.</li>
                <li>Sistema de memoria no volátil EPROM que garantiza el mantenimiento de la información de acceso incluso con el cambio de pilas.</li>
                <li>Apertura de emergencia a través de llave mecánica.</li>
                <li>Disponible en versión para Puerta de Correr o con máquina especial para puertas con perfil estrecho (contáctenos para más información).</li>
                <li>Alerta sonora y visual de baterías bajas.</li>
                <li>Especificaciones mecánicas: Máquina EURO 22 mm con backset de 65 mm. Pestillo de seguridad macizo.</li>
                <li>Auditoría de las últimas 400 aperturas, ya sea por llave mecánica o por tarjeta, indicando Fecha; Hora; Nombre de quien accedió (en el caso de empleados); Quien hizo la tarjeta de acceso y cuándo se hizo la tarjeta</li>
                <li>Aplicabilidad: puertas de 35-80 mm.</li>
            </ul>
            <br/>

            <span>
                <a href='https://3dwarehouse.sketchup.com/model/87f79b1d-dcf9-47f9-b6ca-c7969aadc9f7/Saga-Systems-BIM-Modelo-Fechadura-Elegante-Hotel' target="_blank" rel="noreferrer">¡Haga clic aquí!</a> 
                {' '}
                para ver nuestro Modelo en 3D para BIM!
            </span>
            <br/> <br/>

            <span> 
                ¿Quiere mejorar la seguridad en su hotel? Lea nuestro artículo 
                {' '}
                <a href='https://saga.greenmake.com.br/blog/2020/08/05/fechaduras-para-hotel/' target="_blank" rel="noreferrer">haga clic aquí</a>!
            </span>
            <br/> <br/>

            <span> 
                <b>Atención:</b> 
                {' '}
                Los acabados Golden, Bronze y Negro Mate se hacen por encargo.
            </span>
            <br/>

            <span>¡Favor verificar la disponibilidad con nuestro equipo!</span>
        </>,
    },

    electronicLock2: {
        pt_br: `Omega`,
        en_us: `Omega`,
        es_es: `Omega`
    },

    electronicLock2Description: {
        pt_br: <>
            <span>Produto produzido com alta tecnologia, design moderno, segurança e qualidade Saga Systems..</span>
            <br/> <br/>
    
            <span> <b>Especificações:</b> </span>
            <ul>
                <li>Mecanismo anti pânico.</li>
                <li>Radiofrequência Padrão Mifare 14443-4</li>
                <li>Funcionamento com 4 pilhas AA, durando em média 2 anos.</li>
                <li>Em aço inoxidável AISI304.</li>
                <li>Integração com software de gestão hoteleira.</li>
                <li>Circuito eletrônico anti-umidade adaptável a qualquer tipo de clima.</li>
                <li>Sistema de memória não volátil EPROM que garante a manutenção das informações de acesso mesmo havendo troca de pilhas.</li>
                <li>Abertura de emergência através de chave mecânica. • Aviso sonoro e visual de baterias fracas, função não-perturbe e alarme de porta mal-fechada.</li>
                <li>Especificações da mecânica: máquina euro 22mm. Trinco de segurança maciço.</li>
                <li>Auditoria das últimas 400 aberturas, indicando forma de abertura, data, hora, nome de quem acessou (no caso de funcionários), quem fez o cartão de acesso e quando fez o cartão.</li>
                <li>Aplicabilidade: porta de 35-80mm.</li>
                <li>Abertura com celular utilizando Bluetooth de Baixo Consumo (BLE).</li>
            </ul>
        </>,
        en_us: <>
            <span>Product made with high technology, modern design, security, and quality by Saga Systems.</span>
            <br/> <br/>
    
            <span> <b>Specifications:</b> </span>
            <ul>
                <li>Anti-panic mechanism.</li>
                <li>Mifare 14443-4 Standard Radio Frequency.</li>
                <li>Operates with 4 AA batteries, lasting on average 2 years.</li>
                <li>Made of AISI304 stainless steel.</li>
                <li>Integration with hotel management software.</li>
                <li>Moisture-proof electronic circuit adaptable to any climate.</li>
                <li>Non-volatile EPROM memory system that ensures access information is maintained even when batteries are changed.</li>
                <li>Emergency opening with a mechanical key. • Sound and visual alert for low batteries, do-not-disturb function, and alarm for improperly closed doors.</li>
                <li>Mechanical specifications: Euro cylinder 22mm. Solid security latch.</li>
                <li>Audit of the last 400 openings, indicating the method of opening, date, time, name of the person who accessed (in the case of employees), who created the access card, and when the card was made.</li>
                <li>Applicability: door thickness of 35-80mm.</li>
                <li>Mobile phone opening using Bluetooth Low Energy (BLE).</li>
            </ul>
        </>,
        es_es: <>
            <span>Producto fabricado con alta tecnología, diseño moderno, seguridad y calidad de Saga Systems.</span>
            <br/> <br/>
    
            <span> <b>Especificaciones:</b> </span>
            <ul>
                <li>Mecanismo anti-pánico.</li>
                <li>Frecuencia de radio estándar Mifare 14443-4.</li>
                <li>Funciona con 4 pilas AA, con una duración promedio de 2 años.</li>
                <li>Hecho de acero inoxidable AISI304.</li>
                <li>Integración con software de gestión hotelera.</li>
                <li>Circuito electrónico a prueba de humedad, adaptable a cualquier tipo de clima.</li>
                <li>Sistema de memoria no volátil EPROM que garantiza el mantenimiento de la información de acceso incluso con el cambio de baterías.</li>
                <li>Apertura de emergencia mediante llave mecánica. • Aviso sonoro y visual de baterías bajas, función no molestar y alarma de puerta mal cerrada.</li>
                <li>Especificaciones mecánicas: cilindro euro de 22 mm. Pestillo de seguridad macizo.</li>
                <li>Auditoría de las últimas 400 aperturas, indicando el método de apertura, fecha, hora, nombre de quien accedió (en el caso de empleados), quién creó la tarjeta de acceso y cuándo se hizo la tarjeta.</li>
                <li>Aplicabilidad: puerta de 35-80 mm.</li>
                <li>Apertura con celular utilizando Bluetooth de bajo consumo (BLE).</li>
            </ul>
        </>
    },    

    electronicLock3: {
        pt_br: `New Prime`,
        en_us: `New Prime`,
        es_es: `Nuevo Prime`
    },

    electronicLock3Description: {
        pt_br: <>
            <span>Fechadura modelo bipartido com tecnologia RFID e mecânica de alta segurança para controle de acesso Saga Systems.</span>
            <br/> <br/>

            <span>Segurança total no estilo alto padrão, com seu design moderno e discreto, se adapta a qualquer ambiente ou decoração assegurando ao seu usuário maior praticidade de acesso e privacidade.</span>
            <br/> <br/>

            <span> <b>Especificações:</b> </span>
            <ul>
                <li>Fechadura Eletrônica RFID Bipartida Off-line.</li>
                <li>Máquina de embutir de alta segurança em aço inoxidável.</li>
                <li>Maçaneta em aço inoxidável com acabamento de alta duração.</li>
                <li>Fixação em 3 pontos com mecanismo antifricção, feita com ferrolhos de aço inoxidável.</li>
                <li>Máquina de 20 mm de espessura equipada com trava de alta resistência.</li>
                <li>Mecanismo antipânico: as linguetas e trava são recolhidas com um único movimento pelo lado de dentro, para saída facilitada em casos de emergência.</li>
                <li>Compatível com a ADA (para PCD).</li>
                <li>Memória Flash reprogramável.</li>
                <li>Funcionamento com 4 pilhas AA com duração acima de 1 ano.</li>
                <li>Auditoria das últimas 1.000 aberturas.</li>
                <li>Cilindro mecânico de alta segurança para abertura de emergência.</li>
            </ul>
        </>,
        en_us: <>
            <span>Split model lock with RFID technology and high-security mechanics for access control by Saga Systems.</span>
            <br/> <br/>

            <span>Complete security in high-standard style, with its modern and discreet design, adapts to any environment or decor, ensuring greater access convenience and privacy for its user.</span>
            <br/> <br/>

            <span> <b>Specifications:</b> </span>
            <ul>
                <li>Offline Split RFID Electronic Lock.</li>
                <li>High-security recessed machine made of stainless steel.</li>
                <li>Stainless steel handle with long-lasting finish.</li>
                <li>Three-point fixation with anti-friction mechanism, made with stainless steel bolts.</li>
                <li>20 mm thick machine equipped with high-resistance lock.</li>
                <li>Anti-panic mechanism: the latches and lock are retracted with a single movement from the inside, allowing easy exit in emergencies.</li>
                <li>ADA compatible (for PWD).</li>
                <li>Reprogrammable Flash memory.</li>
                <li>Operates on 4 AA batteries lasting over 1 year.</li>
                <li>Audit of the last 1,000 openings.</li>
                <li>High-security mechanical cylinder for emergency opening.</li>
            </ul>
        </>,
        es_es: <>
            <span>Cerradura de modelo dividido con tecnología RFID y mecánica de alta seguridad para control de acceso de Saga Systems.</span>
            <br/> <br/>

            <span>Seguridad total en estilo de alta gama, con su diseño moderno y discreto, se adapta a cualquier entorno o decoración, asegurando al usuario mayor comodidad de acceso y privacidad.</span>
            <br/> <br/>

            <span> <b>Especificaciones:</b> </span>
            <ul>
                <li>Cerradura Electrónica RFID Dividida Fuera de Línea.</li>
                <li>Máquina empotrada de alta seguridad hecha de acero inoxidable.</li>
                <li>Manija de acero inoxidable con acabado de larga duración.</li>
                <li>Fijación en 3 puntos con mecanismo antifricción, hecha con pestillos de acero inoxidable.</li>
                <li>Máquina de 20 mm de espesor equipada con cerradura de alta resistencia.</li>
                <li>Mecanismo antipánico: las lengüetas y la cerradura se retraen con un solo movimiento desde el interior, permitiendo una salida fácil en emergencias.</li>
                <li>Compatible con la ADA (para PCD).</li>
                <li>Memoria Flash reprogramable.</li>
                <li>Funciona con 4 pilas AA que duran más de 1 año.</li>
                <li>Auditoría de las últimas 1,000 aperturas.</li>
                <li>Cilindro mecánico de alta seguridad para apertura de emergencia.</li>
            </ul>
        </>,
    },

    electronicLock4: {
        pt_br: `Fechadura RFID New`,
        en_us: `New RFID Lock`,
        es_es: `Cerradura RFID Nueva`
    },

    electronicLock4Description: {
        pt_br: <>
            <span>Tecnologia e segurança para hotéis, escolas e hospitais.</span>
            <br/>

            <ul>
                <li>Tecnologia Mifare (de proximidade)</li>
                <li>Mais segurança</li>
                <li>Adaptável a qualquer porta de armário</li>
                <li>Resistente ao fogo</li>
                <li>Facilidade de uso em casos de emergência</li>
            </ul>
            <br/>

            <span> <b>Especificações gerais:</b> </span>
            <ul>
                <li>Funcionamento com 4 pilhas AA, durando mais de um ano (30.000 aberturas).</li>
                <li>Em aço inoxidável AISI304.</li>
                <li>Possibilidade de criação de chave-mestra (cartão).</li>
                <li>Circuito eletrônico anti-umidade adaptável a qualquer tipo de clima.</li>
                <li>Sistema de memória não volátil EPROM que garante a manutenção das informações de acesso mesmo havendo troca de pilhas.</li>
                <li>Mecanismo de abertura de emergência em caso de esgotamento das pilhas.</li>
                <li>Aviso de pilhas fracas, a partir do qual a fechadura ainda funciona por pelo menos 60 operações de abertura.</li>
                <li>Dimensões: 55 (L) x 118 (A) x 20 (P) mm (externa) e 66 (L) x 133 (A) x 27.5 (P) (interna).</li>
                <li>Aviso sonoro e visual de porta mal fechada.</li>
            </ul>
        </>,
        en_us: <>
            <span>Technology and security for hotels, schools, and hospitals.</span>
            <br/>

            <ul>
                <li>Mifare (proximity) technology.</li>
                <li>Enhanced security.</li>
                <li>Adaptable to any cabinet door.</li>
                <li>Fire-resistant.</li>
                <li>Easy to use in emergencies.</li>
            </ul>
            <br/>

            <span> <b>General specifications:</b> </span>
            <ul>
                <li>Operates on 4 AA batteries, lasting over a year (30,000 openings).</li>
                <li>Made of stainless steel AISI304.</li>
                <li>Master key (card) creation possible.</li>
                <li>Humidity-resistant electronic circuit adaptable to any climate.</li>
                <li>Non-volatile EPROM memory system ensuring access information is retained even when batteries are replaced.</li>
                <li>Emergency opening mechanism in case of battery exhaustion.</li>
                <li>Low battery alert, after which the lock still works for at least 60 opening operations.</li>
                <li>Dimensions: 55 (W) x 118 (H) x 20 (D) mm (external) and 66 (W) x 133 (H) x 27.5 (D) mm (internal).</li>
                <li>Audible and visual alert for improperly closed door.</li>
            </ul>
        </>,
        es_es: <>
            <span>Tecnología y seguridad para hoteles, escuelas y hospitales.</span>
            <br/>

            <ul>
                <li>Tecnología Mifare (de proximidad).</li>
                <li>Mayor seguridad.</li>
                <li>Adaptable a cualquier puerta de armario.</li>
                <li>Resistente al fuego.</li>
                <li>Fácil de usar en emergencias.</li>
            </ul>
            <br/>

            <span> <b>Especificaciones generales:</b> </span>
            <ul>
                <li>Funciona con 4 pilas AA, que duran más de un año (30,000 aperturas).</li>
                <li>Hecho de acero inoxidable AISI304.</li>
                <li>Posibilidad de crear llave maestra (tarjeta).</li>
                <li>Circuito electrónico resistente a la humedad, adaptable a cualquier tipo de clima.</li>
                <li>Sistema de memoria no volátil EPROM que garantiza el mantenimiento de la información de acceso incluso al cambiar las pilas.</li>
                <li>Mecanismo de apertura de emergencia en caso de agotamiento de las pilas.</li>
                <li>Alerta de pilas bajas, tras la cual la cerradura aún funciona al menos 60 operaciones de apertura.</li>
                <li>Dimensiones: 55 (A) x 118 (L) x 20 (P) mm (externo) y 66 (A) x 133 (L) x 27.5 (P) mm (interno).</li>
                <li>Alerta sonora y visual de puerta mal cerrada.</li>
            </ul>
        </>,
    },

    electronicLock5: {
        pt_br: `Fechadura de Senha Touchscreen`,
        en_us: `Touchscreen Password Lock`,
        es_es: `Cerradura de Contraseña Táctil`
    },

    electronicLock5Description: {
        pt_br: <>
            <span>Tecnologia e segurança para hotéis, escolas e hospitais.</span>
            <br/>

            <ul>
                <li>Teclado Numérico Touchscreen</li>
                <li>Mais segurança</li>
                <li>Adaptável a qualquer porta de armário</li>
                <li>Resistente ao fogo</li>
                <li>Facilidade de uso em casos de emergência</li>
            </ul>
            <br/>

            <span> <b>Especificações gerais:</b> </span>
            <ul>
                <li>Funcionamento com 4 pilhas AA, durando mais de um ano (30.000 aberturas)</li>
                <li>Em aço inoxidável AISI304</li>
                <li>Possibilidade de criação de senhas-mestras</li>
                <li>Circuito eletrônico anti-umidade adaptável a qualquer tipo de clima.</li>
                <li>Sistema de memória não volátil EPROM que garante a manutenção das informações de acesso mesmo havendo troca de pilhas.</li>
                <li>Mecanismo de abertura de emergência em caso de esgotamento das pilhas</li>
                <li>Aviso de pilhas fracas, a partir do qual a fechadura ainda funciona por pelo menos 60 operações de abertura.</li>
                <li>Dimensões: 55 (L) x 118 (A) x 20 (P) mm 66 (L) x 133 (A) x 27.5 (P) (interna)</li>
                <li>Aviso sonoro e visual de porta mal fechada.</li>
            </ul>
            <br/>

            <span> 
                <a href='https://3dwarehouse.sketchup.com/model/1795306c-ad66-4c67-86e7-60899e5db576/Saga-Systems-BIM-Modelo-Fechadura-Arm%C3%A1rio-Touchscreen' target="_blank" rel="noreferrer">Clique aqui</a> 
                {' '}
                para visitar o Modelo 3D BIM para incluir em seu projeto!
            </span>
        </>,
        en_us: <>
            <span>Technology and security for hotels, schools, and hospitals.</span>
            <br/>

            <ul>
                <li>Touchscreen Numeric Keypad</li>
                <li>Increased security</li>
                <li>Adaptable to any cabinet door</li>
                <li>Fire-resistant</li>
                <li>Ease of use in emergencies</li>
            </ul>
            <br/>

            <span> <b>General specifications:</b> </span>
            <ul>
                <li>Operates on 4 AA batteries, lasting over a year (30,000 openings)</li>
                <li>Made of AISI304 stainless steel</li>
                <li>Capability to create master passwords</li>
                <li>Humidity-resistant electronic circuit adaptable to any climate.</li>
                <li>Non-volatile EPROM memory system ensuring access information is maintained even when batteries are replaced.</li>
                <li>Emergency opening mechanism in case of battery exhaustion</li>
                <li>Low battery warning, after which the lock still operates for at least 60 openings.</li>
                <li>Dimensions: 55 (W) x 118 (H) x 20 (D) mm and 66 (W) x 133 (H) x 27.5 (D) mm (internal)</li>
                <li>Audible and visual alert for poorly closed doors.</li>
            </ul>
            <br/>

            <span> 
                <a href='https://3dwarehouse.sketchup.com/model/1795306c-ad66-4c67-86e7-60899e5db576/Saga-Systems-BIM-Modelo-Fechadura-Arm%C3%A1rio-Touchscreen' target="_blank" rel="noreferrer">Click here</a> 
                {' '}
                to visit the 3D BIM Model to include in your project!
            </span>
        </>,
        es_es: <>
            <span>Tecnología y seguridad para hoteles, escuelas y hospitales.</span>
            <br/>

            <ul>
                <li>Teclado Numérico Táctil</li>
                <li>Mayor seguridad</li>
                <li>Adaptable a cualquier puerta de armario</li>
                <li>Resistente al fuego</li>
                <li>Facilidad de uso en casos de emergencia</li>
            </ul>
            <br/>

            <span> <b>Especificaciones generales:</b> </span>
            <ul>
                <li>Funciona con 4 pilas AA, durando más de un año (30,000 aperturas)</li>
                <li>En acero inoxidable AISI304</li>
                <li>Posibilidad de creación de contraseñas maestras</li>
                <li>Circuito electrónico anti-humedad adaptable a cualquier clima.</li>
                <li>Sistema de memoria no volátil EPROM que garantiza el mantenimiento de la información de acceso incluso con el cambio de pilas.</li>
                <li>Mecanismo de apertura de emergencia en caso de agotamiento de las pilas</li>
                <li>Aviso de pilas bajas, a partir del cual la cerradura todavía funciona por al menos 60 aperturas.</li>
                <li>Dimensiones: 55 (An) x 118 (Al) x 20 (Prof) mm y 66 (An) x 133 (Al) x 27.5 (Prof) mm (interna)</li>
                <li>Alerta sonora y visual de puerta mal cerrada.</li>
            </ul>
            <br/>

            <span> 
                <a href='https://3dwarehouse.sketchup.com/model/1795306c-ad66-4c67-86e7-60899e5db576/Saga-Systems-BIM-Modelo-Fechadura-Arm%C3%A1rio-Touchscreen' target="_blank" rel="noreferrer">¡Haga clic aquí!</a> 
                {' '}
                para visitar el Modelo 3D BIM para incluir en su proyecto!
            </span>
        </>,
    },

    electronicLocksDescription: {
        pt_br: `As fechaduras eletrônicas da Saga Systems são sinônimo de segurança e eficiência em hospitalidade. Com tecnologia de ponta, oferecem acesso fácil e controle aprimorado para hotéis, garantindo uma experiência segura e moderna para hóspedes. A confiança dos clientes na Saga Systems reflete-se na sua posição de liderança no setor.`,
        en_us: `Saga Systems electronic locks are synonymous with security and efficiency in hospitality. With cutting-edge technology, they provide easy access and enhanced control for hotels, ensuring a safe and modern experience for guests. Customer trust in Saga Systems is reflected in its leadership position in the industry.`,
        es_es: `Las cerraduras electrónicas de Saga Systems son sinónimo de seguridad y eficiencia en la hospitalidad. Con tecnología de vanguardia, ofrecen acceso fácil y control mejorado para hoteles, garantizando una experiencia segura y moderna para los huéspedes. La confianza de los clientes en Saga Systems se refleja en su posición de liderazgo en el sector.`
    },

    electronicLocksHomeDescription: {
        pt_br: `Tecnologia de segurança e controle de acesso para seu empreendimento do ramo de hospitalidade`,
        en_us: `Security technology and access control for your hospitality business`,
        es_es: `Tecnología de seguridad y control de acceso para su negocio de hospitalidad`
    },

    electronicSafe1: {
        pt_br: `Exclusive`,
        en_us: `Exclusive`,
        es_es: `Exclusivo`
    },

    electronicSafe1Description: {
        pt_br: <>
            <span> <b>Segurança eletrônica:</b> </span>
            <ul>
                <li>Mecanismo eletrônico e mecânico estão instalados na tampa da porta;</li>
                <li>Cada cofre possui duas chaves mecânicas, com um código de identificação;</li>
                <li>Eletrônica desenhada para resistir a fortes descargas elétricas;</li>
                <li>Após digitar quatro vezes o código errado, o cofre permanecerá bloqueado durante um tempo estabelecido;</li>
                <li>Possui Unidade Portátil opcional para abertura de emergência, protegido com código de acesso.</li>
            </ul>
            <br/>

            <span> <b>Segurança física:</b> </span>
            <ul>
                <li>Duas travas sólidas;</li>
                <li>Porta protegida contra inserção;</li>
                <li>Proteção interna do circuito.</li>
            </ul>
            <br/>

            <span> <b>Auditoria:</b> </span>
            <ul>
                <li>Memória não volátil</li>
                <li>Relatório das 100 últimas operações, podendo ser realizada pelo administrador do sistema de duas maneiras:</li>
                <li>Direto no teclado do cofre;</li>
                <li>Utilizando a Unidade Portátil.</li>
                <li>Data e hora da operação (abriu/fechou com senha do hóspede, senha master, Unidade Portátil ou chave mecânica).</li>
            </ul>
            <br/>

            <span> <b>Opções de abertura:</b> </span>
            <ul>
                <li>Senha do hóspede (quatro à seis dígitos);</li>
                <li>Unidade Portátil;</li>
                <li>Chave mecânica (caso de emergência).</li>
            </ul>
            <br/>

            <span> <b>Dimensões e especificações físicas:</b> </span>
            <ul>
                <li>Externas: (Altura) 190 x (Largura) 435 x (Profundidade) 370 mm</li>
                <li>Internas: (Altura) 184 x (Largura) 430 x (Profundidade) 315 mm</li>
                <li>Porta: (Altura) 154 x (Largura) 355 mm</li>
                <li>Espessura da Chapa do Corpo: 2 mm</li>
                <li>Espessura da Chapa da Porta: 5 mm</li>
                <li>Peso Líquido: 14.5 kg</li>
            </ul>
            <br/>

            <span> <b>Instalação e manutenção:</b> </span>
            <ul>
                <li>Acompanha duas buchas de aço para a fixação do cofre.</li>
                <li>A troca das pilhas é fácil;</li>
                <li>Baixo consumo, média de dois anos de utilização (já acompanha pilhas de fábrica);</li>
                <li>Indicador do status das pilhas no visor.</li>
            </ul>
            <br/>

            <span> <b>Certificados:</b> </span>
            <ul>
                <li>AE 50043021 0001 (TUV Rheinland)</li>
                <li>ISO 9001-2008 (Certificado de qualidade de gestão)</li>
            </ul>
        </>,
        en_us: <>
            <span> <b>Electronic Security:</b> </span>
            <ul>
                <li>Electronic and mechanical mechanisms are installed on the door lid;</li>
                <li>Each safe has two mechanical keys with an identification code;</li>
                <li>Electronics designed to withstand strong electrical surges;</li>
                <li>After entering the wrong code four times, the safe will remain locked for a set time;</li>
                <li>Has an optional Portable Unit for emergency opening, protected with an access code.</li>
            </ul>
            <br/>

            <span> <b>Physical Security:</b> </span>
            <ul>
                <li>Two solid locks;</li>
                <li>Door protected against insertion;</li>
                <li>Internal circuit protection.</li>
            </ul>
            <br/>

            <span> <b>Audit:</b> </span>
            <ul>
                <li>Non-volatile memory</li>
                <li>Report of the last 100 operations, which can be accessed by the system administrator in two ways:</li>
                <li>Directly on the safe's keypad;</li>
                <li>Using the Portable Unit.</li>
                <li>Date and time of the operation (opened/closed with guest password, master password, Portable Unit, or mechanical key).</li>
            </ul>
            <br/>

            <span> <b>Opening Options:</b> </span>
            <ul>
                <li>Guest password (four to six digits);</li>
                <li>Portable Unit;</li>
                <li>Mechanical key (in case of emergency).</li>
            </ul>
            <br/>

            <span> <b>Dimensions and Physical Specifications:</b> </span>
            <ul>
                <li>External: (Height) 190 x (Width) 435 x (Depth) 370 mm</li>
                <li>Internal: (Height) 184 x (Width) 430 x (Depth) 315 mm</li>
                <li>Door: (Height) 154 x (Width) 355 mm</li>
                <li>Body Plate Thickness: 2 mm</li>
                <li>Door Plate Thickness: 5 mm</li>
                <li>Net Weight: 14.5 kg</li>
            </ul>
            <br/>

            <span> <b>Installation and Maintenance:</b> </span>
            <ul>
                <li>Includes two steel anchors for securing the safe.</li>
                <li>Battery replacement is easy;</li>
                <li>Low consumption, average of two years of use (batteries included);</li>
                <li>Battery status indicator on the display.</li>
            </ul>
            <br/>

            <span> <b>Certificates:</b> </span>
            <ul>
                <li>AE 50043021 0001 (TUV Rheinland)</li>
                <li>ISO 9001-2008 (Quality Management Certificate)</li>
            </ul>
        </>,
        es_es: <>
            <span> <b>Seguridad electrónica:</b> </span>
            <ul>
                <li>Mecanismos electrónicos y mecánicos están instalados en la tapa de la puerta;</li>
                <li>Cada caja fuerte tiene dos llaves mecánicas, con un código de identificación;</li>
                <li>Electrónica diseñada para resistir fuertes descargas eléctricas;</li>
                <li>Después de ingresar el código incorrecto cuatro veces, la caja fuerte permanecerá bloqueada durante un tiempo establecido;</li>
                <li>Posee Unidad Portátil opcional para apertura de emergencia, protegida con código de acceso.</li>
            </ul>
            <br/>

            <span> <b>Seguridad física:</b> </span>
            <ul>
                <li>Dos cerraduras sólidas;</li>
                <li>Puerta protegida contra inserción;</li>
                <li>Protección interna del circuito.</li>
            </ul>
            <br/>

            <span> <b>Auditoría:</b> </span>
            <ul>
                <li>Memoria no volátil</li>
                <li>Informe de las últimas 100 operaciones, que puede ser realizado por el administrador del sistema de dos maneras:</li>
                <li>Directamente en el teclado de la caja fuerte;</li>
                <li>Utilizando la Unidad Portátil.</li>
                <li>Fecha y hora de la operación (abierta/cerrada con contraseña del huésped, contraseña maestra, Unidad Portátil o llave mecánica).</li>
            </ul>
            <br/>

            <span> <b>Opciones de apertura:</b> </span>
            <ul>
                <li>Contraseña del huésped (de cuatro a seis dígitos);</li>
                <li>Unidad Portátil;</li>
                <li>Llave mecánica (en caso de emergencia).</li>
            </ul>
            <br/>

            <span> <b>Dimensiones y especificaciones físicas:</b> </span>
            <ul>
                <li>Externas: (Altura) 190 x (Ancho) 435 x (Profundidad) 370 mm</li>
                <li>Internas: (Altura) 184 x (Ancho) 430 x (Profundidad) 315 mm</li>
                <li>Puerta: (Altura) 154 x (Ancho) 355 mm</li>
                <li>Grosor de la chapa del cuerpo: 2 mm</li>
                <li>Grosor de la chapa de la puerta: 5 mm</li>
                <li>Peso neto: 14.5 kg</li>
            </ul>
            <br/>

            <span> <b>Instalación y mantenimiento:</b> </span>
            <ul>
                <li>Incluye dos tacos de acero para la fijación de la caja fuerte.</li>
                <li>El cambio de pilas es fácil;</li>
                <li>Bajo consumo, promedio de dos años de uso (ya incluye pilas de fábrica);</li>
                <li>Indicador de estado de las pilas en el visor.</li>
            </ul>
            <br/>

            <span> <b>Certificados:</b> </span>
            <ul>
                <li>AE 50043021 0001 (TUV Rheinland)</li>
                <li>ISO 9001-2008 (Certificado de calidad de gestión)</li>
            </ul>
        </>,
    },

    electronicSafe2: {
        pt_br: `Laptop Classic Bege`,
        en_us: `Classic Laptop Beige`,
        es_es: `Portátil Clásico Beige`
    },

    electronicSafe2Description: {
        pt_br: <>
            <span>O cofre mais conhecido e confiado do mercado hoteleiro! Nosso cofre Classic é projetado para comportar um Laptop, além de bolsas de mão, documentos e objetos de valor.</span>
            <br/> <br/>

            <span>Os cofres eletrônicos são um produto imprescindível num apartamento de hotel, e devem ser adquiridos levando-se em consideração características de qualidade e características técnicas.</span>
            <br/> <br/>

            <span>Um cofre eletrônico bem escolhido traz tranquilidade para o hoteleiro e segurança para o hóspede.</span>
            <br/> <br/>

            <span> <b>Segurança eletrônica:</b> </span>
            <ul>
                <li>Mecanismo eletrônico e mecânico estão instalados na tampa da porta;</li>
                <li>Cada cofre possui duas chaves mecânicas, com um código de identificação;</li>
                <li>Eletrônica desenhada para resistir a fortes descargas elétricas;</li>
                <li>Após digitar quatro vezes o código errado, o cofre permanecerá bloqueado durante um tempo estabelecido;</li>
                <li>Possui Unidade Portátil opcional para abertura de emergência, protegido com código de acesso.</li>
            </ul>
            <br/>

            <span> <b>Segurança física:</b> </span>
            <ul>
                <li>Duas travas sólidas;</li>
                <li>Porta protegida contra inserção;</li>
                <li>Proteção interna do circuito.</li>
            </ul>
            <br/>

            <span> <b>Auditoria:</b> </span>
            <ul>
                <li>Memória não volátil</li>
                <li>Relatório das 100 últimas operações, podendo ser realizada pelo administrador do sistema de duas maneiras:</li>
                <li>Direto no teclado do cofre;</li>
                <li>Utilizando a Unidade Portátil.</li>
                <li>Data e hora da operação (abriu/fechou com senha do hóspede, senha master, Unidade Portátil ou chave mecânica).</li>
            </ul>
            <br/>

            <span> <b>Opções de abertura:</b> </span>
            <ul>
                <li>Senha do hóspede (quatro à seis dígitos);</li>
                <li>Unidade Portátil;</li>
                <li>Chave mecânica (caso de emergência).</li>
            </ul>
            <br/>

            <span> <b>Dimensões e especificações físicas:</b> </span>
            <ul>
                <li>Externas: (Altura) 200 x (Largura) 420 x (Profundidade) 370 mm</li>
                <li>Peso: 12 kg</li>
                <li>Internas: (Altura) 195 x (Largura) 417 x (Profundidade) 318 mm</li>
            </ul>
            <br/>

            <span> <b>Instalação e manutenção:</b> </span>
            <ul>
                <li>Acompanha duas buchas de aço para a fixação do cofre.</li>
                <li>A troca das pilhas é fácil;</li>
                <li>Baixo consumo, média de dois anos de utilização (já acompanha pilhas de fábrica);</li>
                <li>Indicador do status das pilhas no visor.</li>
            </ul>
            <br/>

            <span>Disponível nas cores Preto e Bege</span>
            <br/>

            <span>Para mais opções de cores, entrar em contato conosco</span>
            <br/> <br/>

            <span> 
                Veja a publicação em nosso blog sobre como escolher um bom cofre para seu hotel
                {' '}
                <a href='https://saga.greenmake.com.br/blog/2019/05/08/cofre-para-hotel/' target="_blank" rel="noreferrer">clicando aqui</a>! 
            </span>
        </>,
        en_us: <>
            <span>The most well-known and trusted safe in the hotel market! Our Classic safe is designed to accommodate a laptop, as well as handbags, documents, and valuable items.</span>
            <br/> <br/>

            <span>Electronic safes are an essential product in a hotel room and should be purchased considering quality and technical characteristics.</span>
            <br/> <br/>

            <span>A well-chosen electronic safe provides peace of mind for hotel staff and security for guests.</span>
            <br/> <br/>

            <span> <b>Electronic Security:</b> </span>
            <ul>
                <li>Electronic and mechanical mechanisms are installed on the door lid;</li>
                <li>Each safe has two mechanical keys with an identification code;</li>
                <li>Electronics designed to withstand strong electrical surges;</li>
                <li>After entering the wrong code four times, the safe will remain locked for a set time;</li>
                <li>Has an optional Portable Unit for emergency opening, protected with an access code.</li>
            </ul>
            <br/>

            <span> <b>Physical Security:</b> </span>
            <ul>
                <li>Two solid locks;</li>
                <li>Door protected against insertion;</li>
                <li>Internal circuit protection.</li>
            </ul>
            <br/>

            <span> <b>Audit:</b> </span>
            <ul>
                <li>Non-volatile memory</li>
                <li>Report of the last 100 operations, which can be accessed by the system administrator in two ways:</li>
                <li>Directly on the safe's keypad;</li>
                <li>Using the Portable Unit.</li>
                <li>Date and time of the operation (opened/closed with guest password, master password, Portable Unit, or mechanical key).</li>
            </ul>
            <br/>

            <span> <b>Opening Options:</b> </span>
            <ul>
                <li>Guest password (four to six digits);</li>
                <li>Portable Unit;</li>
                <li>Mechanical key (in case of emergency).</li>
            </ul>
            <br/>

            <span> <b>Dimensions and Physical Specifications:</b> </span>
            <ul>
                <li>External: (Height) 200 x (Width) 420 x (Depth) 370 mm</li>
                <li>Weight: 12 kg</li>
                <li>Internal: (Height) 195 x (Width) 417 x (Depth) 318 mm</li>
            </ul>
            <br/>

            <span> <b>Installation and Maintenance:</b> </span>
            <ul>
                <li>Includes two steel anchors for securing the safe.</li>
                <li>Battery replacement is easy;</li>
                <li>Low consumption, average of two years of use (batteries included);</li>
                <li>Battery status indicator on the display.</li>
            </ul>
            <br/>

            <span>Available in Black and Beige colors</span>
            <br/>

            <span>For more color options, please contact us</span>
            <br/> <br/>

            <span> 
                See our blog post on how to choose a good safe for your hotel
                {' '}
                <a href='https://saga.greenmake.com.br/blog/2019/05/08/cofre-para-hotel/' target="_blank" rel="noreferrer">by clicking here</a>! 
            </span>
        </>,
        es_es: <>
            <span>¡La caja fuerte más conocida y confiable del mercado hotelero! Nuestra caja fuerte Classic está diseñada para acomodar una laptop, así como bolsos de mano, documentos y objetos de valor.</span>
            <br/> <br/>

            <span>Las cajas fuertes electrónicas son un producto esencial en una habitación de hotel y deben adquirirse considerando características de calidad y técnicas.</span>
            <br/> <br/>

            <span>Una caja fuerte electrónica bien elegida proporciona tranquilidad al personal del hotel y seguridad a los huéspedes.</span>
            <br/> <br/>

            <span> <b>Seguridad electrónica:</b> </span>
            <ul>
                <li>Mecanismos electrónicos y mecánicos están instalados en la tapa de la puerta;</li>
                <li>Cada caja fuerte tiene dos llaves mecánicas con un código de identificación;</li>
                <li>Electrónica diseñada para soportar fuertes sobrecargas eléctricas;</li>
                <li>Después de ingresar el código incorrecto cuatro veces, la caja fuerte permanecerá bloqueada durante un tiempo establecido;</li>
                <li>Dispone de una Unidad Portátil opcional para apertura de emergencia, protegida con un código de acceso.</li>
            </ul>
            <br/>

            <span> <b>Seguridad física:</b> </span>
            <ul>
                <li>Dos cerraduras sólidas;</li>
                <li>Puerta protegida contra inserciones;</li>
                <li>Protección del circuito interno.</li>
            </ul>
            <br/>

            <span> <b>Auditoría:</b> </span>
            <ul>
                <li>Memoria no volátil</li>
                <li>Informe de las últimas 100 operaciones, que puede ser accedido por el administrador del sistema de dos maneras:</li>
                <li>Directamente en el teclado de la caja fuerte;</li>
                <li>Utilizando la Unidad Portátil.</li>
                <li>Fecha y hora de la operación (abierto/cerrado con contraseña de huésped, contraseña maestra, Unidad Portátil o llave mecánica).</li>
            </ul>
            <br/>

            <span> <b>Opciones de apertura:</b> </span>
            <ul>
                <li>Contraseña del huésped (de cuatro a seis dígitos);</li>
                <li>Unidad Portátil;</li>
                <li>Llave mecánica (en caso de emergencia).</li>
            </ul>
            <br/>

            <span> <b>Dimensiones y especificaciones físicas:</b> </span>
            <ul>
                <li>Externas: (Altura) 200 x (Ancho) 420 x (Profundidad) 370 mm</li>
                <li>Peso: 12 kg</li>
                <li>Internas: (Altura) 195 x (Ancho) 417 x (Profundidad) 318 mm</li>
            </ul>
            <br/>

            <span> <b>Instalación y mantenimiento:</b> </span>
            <ul>
                <li>Incluye dos tacos de acero para la fijación de la caja fuerte.</li>
                <li>El cambio de pilas es fácil;</li>
                <li>Bajo consumo, promedio de dos años de uso (ya incluye pilas de fábrica);</li>
                <li>Indicador de estado de las pilas en el visor.</li>
            </ul>
            <br/>

            <span>Disponible en colores Negro y Beige</span>
            <br/>

            <span>Para más opciones de colores, contáctenos</span>
            <br/> <br/>

            <span> 
                Vea nuestra publicación en el blog sobre cómo elegir una buena caja fuerte para su hotel
                {' '}
                <a href='https://saga.greenmake.com.br/blog/2019/05/08/cofre-para-hotel/' target="_blank" rel="noreferrer">haga clic aquí</a>! 
            </span>
        </>,
    },

    electronicSafe3: {
        pt_br: `Laptop Classic Preto`,
        en_us: `Classic Laptop Black`,
        es_es: `Laptop Classic Negro`
    },
    
    electronicSafe3Description: {
        pt_br: <>
            <span>O cofre mais conhecido e confiado do mercado hoteleiro! Projetado para comportar um Laptop, além de bolsas de mão e objetos de valor.</span>
            <br/> <br/>
    
            <span> <b>Segurança eletrônica:</b> </span>
            <ul>
                <li>Mecanismo eletrônico e mecânico estão instalados na tampa da porta;</li>
                <li>Cada cofre possui duas chaves mecânicas, com um código de identificação;</li>
                <li>Eletrônica desenhada para resistir a fortes descargas elétricas;</li>
                <li>Após digitar quatro vezes o código errado, o cofre permanecerá bloqueado durante um tempo estabelecido;</li>
                <li>Possui Unidade Portátil opcional para abertura de emergência, protegido com código de acesso.</li>
            </ul>
            <br/>
    
            <span> <b>Segurança física:</b> </span>
            <ul>
                <li>Duas travas sólidas;</li>
                <li>Porta protegida contra inserção;</li>
                <li>Proteção interna do circuito.</li>
            </ul>
            <br/>
    
            <span> <b>Auditoria:</b> </span>
            <ul>
                <li>Memória não volátil</li>
                <li>Relatório das 100 últimas operações, podendo ser realizada pelo administrador do sistema de duas maneiras:</li>
                <li>Direto no teclado do cofre;</li>
                <li>Utilizando a Unidade Portátil.</li>
                <li>Data e hora da operação (abriu/fechou com senha do hóspede, senha master, Unidade Portátil ou chave mecânica).</li>
            </ul>
            <br/>
    
            <span> <b>Opções de abertura:</b> </span>
            <ul>
                <li>Senha do hóspede (quatro à seis dígitos);</li>
                <li>Unidade Portátil;</li>
                <li>Chave mecânica (caso de emergência).</li>
            </ul>
            <br/>
    
            <span> <b>Dimensões e especificações físicas:</b> </span>
            <ul>
                <li>Externas: (Altura) 200 x (Largura) 420 x (Profundidade) 370 mm</li>
                <li>Peso: 12 kg</li>
                <li>Internas: (Altura) 195 x (Largura) 417 x (Profundidade) 318 mm</li>
            </ul>
            <br/>
    
            <span> <b>Instalação e manutenção:</b> </span>
            <ul>
                <li>Acompanha duas buchas de aço para a fixação do cofre.</li>
                <li>A troca das pilhas é fácil;</li>
                <li>Baixo consumo, média de dois anos de utilização (já acompanha pilhas de fábrica);</li>
                <li>Indicador do status das pilhas no visor.</li>
            </ul>
            <br/>
    
            <span>Disponível nas cores Preto e Bege</span>
            <br/>
    
            <span>Para mais opções de cores, entrar em contato conosco</span>
            <br/> <br/>
    
            <span> 
                <a href='https://3dwarehouse.sketchup.com/model/264ffda6-a123-46bc-98cc-ea3f7143403b/Saga-Systems-BIM-Modelo-Cofre-Classic-Laptop-Preto' target="_blank" rel="noreferrer">Clique aqui</a>
                {' '}
                para acessar e baixar o modelo 3D para BIM do Cofre Laptop Classic na cor Preto!
            </span>
            <br/> <br/>
    
            <span> 
                Veja a publicação em nosso blog sobre como escolher um bom cofre para seu hotel
                {' '}
                <a href='https://saga.greenmake.com.br/blog/2018/11/21/1970/' target="_blank" rel="noreferrer">clicando aqui</a>! 
            </span>
        </>,
        en_us: <>
            <span>The most well-known and trusted safe in the hotel market! Designed to hold a laptop, as well as handbags and valuables.</span>
            <br/> <br/>
    
            <span> <b>Electronic security:</b> </span>
            <ul>
                <li>Electronic and mechanical mechanisms are installed on the door lid;</li>
                <li>Each safe comes with two mechanical keys, with an identification code;</li>
                <li>Electronics designed to withstand high electrical surges;</li>
                <li>After entering the wrong code four times, the safe will remain locked for a set time;</li>
                <li>Optional Portable Unit for emergency opening, protected by access code.</li>
            </ul>
            <br/>
    
            <span> <b>Physical security:</b> </span>
            <ul>
                <li>Two solid locks;</li>
                <li>Door protected against insertion;</li>
                <li>Internal circuit protection.</li>
            </ul>
            <br/>
    
            <span> <b>Audit:</b> </span>
            <ul>
                <li>Non-volatile memory</li>
                <li>Report of the last 100 operations, which can be performed by the system administrator in two ways:</li>
                <li>Directly on the safe's keyboard;</li>
                <li>Using the Portable Unit.</li>
                <li>Date and time of the operation (opened/closed with guest password, master password, Portable Unit, or mechanical key).</li>
            </ul>
            <br/>
    
            <span> <b>Opening options:</b> </span>
            <ul>
                <li>Guest password (four to six digits);</li>
                <li>Portable Unit;</li>
                <li>Mechanical key (in case of emergency).</li>
            </ul>
            <br/>
    
            <span> <b>Dimensions and physical specifications:</b> </span>
            <ul>
                <li>External: (Height) 200 x (Width) 420 x (Depth) 370 mm</li>
                <li>Weight: 12 kg</li>
                <li>Internal: (Height) 195 x (Width) 417 x (Depth) 318 mm</li>
            </ul>
            <br/>
    
            <span> <b>Installation and maintenance:</b> </span>
            <ul>
                <li>Includes two steel plugs for securing the safe.</li>
                <li>Battery replacement is easy;</li>
                <li>Low consumption, average of two years of use (comes with factory batteries);</li>
                <li>Battery status indicator on the display.</li>
            </ul>
            <br/>
    
            <span>Available in Black and Beige colors</span>
            <br/>
    
            <span>For more color options, please contact us</span>
            <br/> <br/>
    
            <span> 
                <a href='https://3dwarehouse.sketchup.com/model/264ffda6-a123-46bc-98cc-ea3f7143403b/Saga-Systems-BIM-Modelo-Cofre-Classic-Laptop-Preto' target="_blank" rel="noreferrer">Click here</a>
                {' '}
                to access and download the 3D model for BIM of the Classic Laptop Safe in Black color!
            </span>
            <br/> <br/>
    
            <span> 
                Check out the post on our blog about how to choose a good safe for your hotel
                {' '}
                <a href='https://saga.greenmake.com.br/blog/2018/11/21/1970/' target="_blank" rel="noreferrer">by clicking here</a>! 
            </span>
        </>,
        es_es: <>
            <span>¡La caja fuerte más conocida y confiable en el mercado hotelero! Diseñada para albergar una laptop, así como bolsas de mano y objetos de valor.</span>
            <br/> <br/>
    
            <span> <b>Seguridad electrónica:</b> </span>
            <ul>
                <li>El mecanismo electrónico y mecánico están instalados en la tapa de la puerta;</li>
                <li>Cada caja fuerte tiene dos llaves mecánicas, con un código de identificación;</li>
                <li>Electrónica diseñada para resistir fuertes descargas eléctricas;</li>
                <li>Después de ingresar cuatro veces el código incorrecto, la caja fuerte permanecerá bloqueada durante un tiempo establecido;</li>
                <li>Posee Unidad Portátil opcional para apertura de emergencia, protegido con un código de acceso.</li>
            </ul>
            <br/>
    
            <span> <b>Seguridad física:</b> </span>
            <ul>
                <li>Dos cerraduras sólidas;</li>
                <li>Puerta protegida contra inserción;</li>
                <li>Protección interna del circuito.</li>
            </ul>
            <br/>
    
            <span> <b>Auditoría:</b> </span>
            <ul>
                <li>Memoria no volátil</li>
                <li>Informe de las últimas 100 operaciones, que puede ser realizado por el administrador del sistema de dos maneras:</li>
                <li>Directamente en el teclado de la caja fuerte;</li>
                <li>Utilizando la Unidad Portátil.</li>
                <li>Fecha y hora de la operación (abierta/cerrada con la contraseña del huésped, contraseña maestra, Unidad Portátil o llave mecánica).</li>
            </ul>
            <br/>
    
            <span> <b>Opciones de apertura:</b> </span>
            <ul>
                <li>Contraseña del huésped (de cuatro a seis dígitos);</li>
                <li>Unidad Portátil;</li>
                <li>Llave mecánica (en caso de emergencia).</li>
            </ul>
            <br/>
    
            <span> <b>Dimensiones y especificaciones físicas:</b> </span>
            <ul>
                <li>Externas: (Altura) 200 x (Ancho) 420 x (Profundidad) 370 mm</li>
                <li>Peso: 12 kg</li>
                <li>Internas: (Altura) 195 x (Ancho) 417 x (Profundidad) 318 mm</li>
            </ul>
            <br/>
    
            <span> <b>Instalación y mantenimiento:</b> </span>
            <ul>
                <li>Incluye dos tacos de acero para fijar la caja fuerte.</li>
                <li>El cambio de baterías es fácil;</li>
                <li>Bajo consumo, promedio de dos años de uso (ya incluye baterías de fábrica);</li>
                <li>Indicador del estado de las baterías en la pantalla.</li>
            </ul>
            <br/>
    
            <span>Disponible en colores Negro y Beige</span>
            <br/>
    
            <span>Para más opciones de colores, contáctenos</span>
            <br/> <br/>
    
            <span> 
                <a href='https://3dwarehouse.sketchup.com/model/264ffda6-a123-46bc-98cc-ea3f7143403b/Saga-Systems-BIM-Modelo-Cofre-Classic-Laptop-Preto' target="_blank" rel="noreferrer">¡Haz clic aquí!</a>
                {' '}
                para acceder y descargar el modelo 3D para BIM de la Caja Fuerte Laptop Classic en color Negro!
            </span>
            <br/> <br/>
    
            <span> 
                Consulta la publicación en nuestro blog sobre cómo elegir una buena caja fuerte para tu hotel
                {' '}
                <a href='https://saga.greenmake.com.br/blog/2018/11/21/1970/' target="_blank" rel="noreferrer">haciendo clic aquí</a>! 
            </span>
        </>,
    },
    
    electronicSafe4: {
        pt_br: `Unidade Portátil (CEU)`,
        en_us: `Portable Unit (CEU)`,
        es_es: `Unidad Portátil (CEU)`
    },
    
    electronicSafe4Description: {
        pt_br: <>
            <span>Conecta-se externamente ao cofre para operações de leitura dos registros de auditoria, programação do número do apartamento e abertura de emergência.</span>
        </>,
        en_us: <>
            <span>Connects externally to the safe for reading audit logs, programming the apartment number, and emergency opening.</span>
        </>,
        es_es: <>
            <span>Se conecta externamente a la caja fuerte para operaciones de lectura de registros de auditoría, programación del número de apartamento y apertura de emergencia.</span>
        </>
    },
    
    electronicSafes: {
        pt_br: `Cofres eletrônicos`,
        en_us: `Electronic safes`,
        es_es: `Cajas fuertes electrónicas`
    },
    
    electronicSafesDescription: {
        pt_br: `A Saga Systems oferece uma linha de cofres que se destaca pela segurança e inovação. Cada modelo é projetado para atender a diferentes necessidades de segurança, proporcionando tranquilidade e proteção para seus bens mais valiosos.`,
        en_us: `Saga Systems offers a line of safes that stands out for security and innovation. Each model is designed to meet different security needs, providing peace of mind and protection for your most valuable belongings.`,
        es_es: `Saga Systems ofrece una línea de cajas fuertes que se destaca por su seguridad e innovación. Cada modelo está diseñado para satisfacer diferentes necesidades de seguridad, proporcionando tranquilidad y protección para sus bienes más valiosos.`
    },
    
    electronicSafesHomeDescription: {
        pt_br: `Um item indispensável para a segurança e tranquilidade de seus hóspedes`,
        en_us: `An essential item for the security and peace of mind of your guests.`,
        es_es: `Un artículo indispensable para la seguridad y tranquilidad de sus huéspedes.`
    },

    energySaver1: {
        pt_br: `Ecoline`,
        en_us: `Ecoline`,
        es_es: `Ecoline`
    },
    
    energySaver1Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>
    
            <span> <b>Sensor IR:</b> </span>
            <br/>
            <span>O sensor IR é uma peça circular com diâmetro de 84mm com uma lente no meio. Dentro dessa lente, fica um elemento sensor e um LED que acende quando a transmissão para a central. Deve ser usado pelo menos um sensor IR por ambiente. Ele deve ser preso no teto com buchas e parafusos ou fita adesivos ou dupla face</span>
            <br/> <br/>
    
            <span> <b>Sensor de porta:</b> </span>
            <br/>
            <span>Este sensor deve ser usado na porta de entrada do quarto. Ele é composto de duas partes: uma que fica no batente da porta (parte eletrônica) e outra que fica na porta (imã)</span>
            <br/> <br/>
    
            <span> <b>Sensor de janela:</b> </span>
            <br/>
            <span>Este sensor é idêntico ao sensor de porta. Apenas o código transmitido muda. O uso desse sensor não é obrigatório, mas é muito útil, pois impede o uso do ar-condicionado com a janela aberta</span>
            <br/> <br/>
    
            <span> <b>Central:</b> </span>
            <br/>
            <span>A central controla os circuitos elétricos do quarto através dos sensores, com auxílio de contatores.</span>
            <br/> <br/>
    
            <span> <b>Contator:</b> </span>
            <br/>
            <span>Um contator é um dispositivo eletromecânico que funciona como um interruptor para acionar circuitos de ambientes. Ele possui uma bobina que é operada por baixa tensão contínua ou alternada e movimenta um conjunto de contatos mecânicos que são adequados para controlar correntes intensas</span>
        </>,
        en_us: <>
            <span>Sustainability in energy savers for hotels.</span>
            <br/> <br/>
    
            <span> <b>IR Sensor:</b> </span>
            <br/>
            <span>The IR sensor is a circular piece with a diameter of 84mm and a lens in the middle. Inside this lens, there is a sensor element and an LED that lights up when transmitting to the central unit. At least one IR sensor should be used per room. It should be mounted on the ceiling with anchors and screws or adhesive or double-sided tape.</span>
            <br/> <br/>
    
            <span> <b>Door Sensor:</b> </span>
            <br/>
            <span>This sensor should be used on the entrance door of the room. It consists of two parts: one that is placed on the door frame (electronic part) and another that is on the door (magnet).</span>
            <br/> <br/>
    
            <span> <b>Window Sensor:</b> </span>
            <br/>
            <span>This sensor is identical to the door sensor. Only the transmitted code differs. The use of this sensor is not mandatory, but it is very useful as it prevents the use of air conditioning with the window open.</span>
            <br/> <br/>
    
            <span> <b>Central Unit:</b> </span>
            <br/>
            <span>The central unit controls the electrical circuits of the room through the sensors, with the aid of contactors.</span>
            <br/> <br/>
    
            <span> <b>Contactor:</b> </span>
            <br/>
            <span>A contactor is an electromechanical device that functions as a switch to operate circuit environments. It has a coil that is operated by low continuous or alternating voltage and moves a set of mechanical contacts suitable for controlling high currents.</span>
        </>,
        es_es: <>
            <span>Sostenibilidad en ahorradores de energía para hoteles.</span>
            <br/> <br/>
    
            <span> <b>Sensor IR:</b> </span>
            <br/>
            <span>El sensor IR es una pieza circular con un diámetro de 84 mm y una lente en el medio. Dentro de esta lente, hay un elemento sensor y un LED que se enciende cuando se transmite a la central. Debe usarse al menos un sensor IR por ambiente. Debe fijarse en el techo con tacos y tornillos o cinta adhesiva o de doble cara.</span>
            <br/> <br/>
    
            <span> <b>Sensor de puerta:</b> </span>
            <br/>
            <span>Este sensor debe usarse en la puerta de entrada de la habitación. Está compuesto por dos partes: una que se coloca en el marco de la puerta (parte electrónica) y otra que se coloca en la puerta (imán).</span>
            <br/> <br/>
    
            <span> <b>Sensor de ventana:</b> </span>
            <br/>
            <span>Este sensor es idéntico al sensor de puerta. Solo cambia el código transmitido. El uso de este sensor no es obligatorio, pero es muy útil, ya que impide el uso del aire acondicionado con la ventana abierta.</span>
            <br/> <br/>
    
            <span> <b>Central:</b> </span>
            <br/>
            <span>La central controla los circuitos eléctricos de la habitación a través de los sensores, con la ayuda de contactores.</span>
            <br/> <br/>
    
            <span> <b>Contactor:</b> </span>
            <br/>
            <span>Un contactor es un dispositivo electromecánico que funciona como un interruptor para accionar circuitos de ambientes. Tiene una bobina que es operada por baja tensión continua o alterna y mueve un conjunto de contactos mecánicos adecuados para controlar corrientes intensas.</span>
        </>
    },    
    
    energySaver2: {
        pt_br: `RFID 01 Interruptores de Luz`,
        en_us: `RFID 01 Light Switches`,
        es_es: `Interruptores de Luz RFID 01`
    },
    
    energySaver2Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>
    
            <span> <b>Funcionamento:</b> </span>
            <br/>
    
            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>
    
            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>
    
            <span>Quando o cartão é retirado do economizador os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>
    
            <span>Possui 1 interruptor para lâmpada e somente funciona mediante a inserção de cartão com chip RFID, mifare (proximidade), devido à existência do sensor de rádio-frequência.</span>
        </>,
        en_us: <>
            <span>Sustainability in energy savers for hotels.</span>
            <br/> <br/>
    
            <span> <b>Operation:</b> </span>
            <br/>
    
            <span>The energy saver is a device that releases electrical energy in a hotel room when a card, the same one used to open the door (with electronic lock), is inserted into its slot.
            <br/>
    
            <span>It switches through contactors or directly connects the circuits of outlets, room lighting, air conditioning unit, etc.</span>
            <br/>
    
            <span>When the card is removed from the saver, the circuits are turned off after approximately 60 seconds, allowing time for the guest to exit the room. At this moment, two pilot lights on the saver body light up to guide and facilitate future card insertion.</span>
            <br/>
    
            <span>It has 1 switch for a light and only works with the insertion of a card with RFID chip, mifare (proximity), due to the presence of the radio-frequency sensor.</span>
            </span>
        </>,
        es_es: <>
            <span>Sostenibilidad en ahorradores de energía para hoteles.</span>
            <br/> <br/>
    
            <span> <b>Funcionamiento:</b> </span>
            <br/>
    
            <span>El ahorrador de energía es un dispositivo que libera la energía eléctrica de una habitación de hotel en el momento en que se inserta una tarjeta, la misma que se utiliza para abrir la puerta (con cerradura electrónica).
            <br/>
    
            <span>Conmutar a través de contactores o directamente los circuitos de tomas, iluminación de la habitación, aparato de aire acondicionado, etc.</span>
            <br/>
    
            <span>Cuando se retira la tarjeta del ahorrador, los circuitos se apagan después de aproximadamente 60 segundos, dando tiempo al huésped para salir de la habitación. En este momento, dos luces piloto en el cuerpo del ahorrador se encienden para orientar y facilitar una futura inserción de la tarjeta.</span>
            <br/>
    
            <span>Posee 1 interruptor para lámpara y solo funciona mediante la inserción de tarjeta con chip RFID, mifare (proximidad), debido a la existencia del sensor de radiofrecuencia.</span>
            </span>
        </>,
    },
    
    energySaver3: {
        pt_br: `RFID 02 Interruptores de Luz`,
        en_us: `RFID 02 Light Switches`,
        es_es: `Interruptores de Luz RFID 02`
    },
    
    energySaver3Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>
    
            <span> <b>Funcionamento:</b> </span>
            <br/>
    
            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>
    
            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>
    
            <span>Quando o cartão é retirado do economizador os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>
    
            <span>Possui 2 interruptores para lâmpadas e somente funciona mediante a inserção de cartão com chip RFID, mifare (proximidade), devido à existência do sensor de rádio-frequência.</span>
        </>,
        en_us: <>
            <span>Sustainability in energy savers for hotels.</span>
            <br/> <br/>
    
            <span> <b>Operation:</b> </span>
            <br/>
    
            <span>The energy saver is a device that releases electrical energy in a hotel room when a card, the same one used to open the door (with electronic lock), is inserted into its slot.</span>
            <br/>
    
            <span>It switches through contactors or directly connects the circuits of outlets, room lighting, air conditioning unit, etc.</span>
            <br/>
    
            <span>When the card is removed from the saver, the circuits are turned off after approximately 60 seconds, allowing time for the guest to exit the room. At this moment, two pilot lights on the saver body light up to guide and facilitate future card insertion.</span>
            <br/>
    
            <span>It has 2 switches for lights and only works with the insertion of a card with RFID chip, mifare (proximity), due to the presence of the radio-frequency sensor.</span>
        </>,
        es_es: <>
            <span>Sostenibilidad en ahorradores de energía para hoteles.</span>
            <br/> <br/>
    
            <span> <b>Funcionamiento:</b> </span>
            <br/>
    
            <span>El ahorrador de energía es un dispositivo que libera la energía eléctrica de una habitación de hotel en el momento en que se inserta una tarjeta, la misma que se utiliza para abrir la puerta (con cerradura electrónica).</span>
            <br/>
    
            <span>Conmutar a través de contactores o directamente los circuitos de tomas, iluminación de la habitación, aparato de aire acondicionado, etc.</span>
            <br/>
    
            <span>Cuando se retira la tarjeta del ahorrador, los circuitos se apagan después de aproximadamente 60 segundos, dando tiempo al huésped para salir de la habitación. En este momento, dos luces piloto en el cuerpo del ahorrador se encienden para orientar y facilitar una futura inserción de la tarjeta.</span>
            <br/>
    
            <span>Posee 2 interruptores para lámparas y solo funciona mediante la inserción de tarjeta con chip RFID, mifare (proximidad), debido a la existencia del sensor de radiofrecuencia.</span>
        </>,
    },
    
    energySaver4: {
        pt_br: `RFID`,
        en_us: `RFID`,
        es_es: `RFID`
    },
    
    energySaver4Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>
    
            <span> <b>Funcionamento:</b> </span>
            <br/>
    
            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>
    
            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>
    
            <span>Quando o cartão é retirado do economizador os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>
    
            <span>Somente funciona mediante a inserção de cartão com chip RFID, mifare (proximidade), devido à existência do sensor de rádio-frequência.</span>
        </>,
        en_us: <>
            <span>Sustainability in energy savers for hotels.</span>
            <br/> <br/>
    
            <span> <b>Operation:</b> </span>
            <br/>
    
            <span>The energy saver is a device that releases electrical energy in a hotel room when a card, the same one used to open the door (with electronic lock), is inserted into its slot.</span>
            <br/>
    
            <span>It switches through contactors or directly connects the circuits of outlets, room lighting, air conditioning unit, etc.</span>
            <br/>
    
            <span>When the card is removed from the saver, the circuits are turned off after approximately 60 seconds, allowing time for the guest to exit the room. At this moment, two pilot lights on the saver body light up to guide and facilitate future card insertion.</span>
            <br/>
    
            <span>It only works with the insertion of a card with RFID chip, mifare (proximity), due to the presence of the radio-frequency sensor.</span>
        </>,
        es_es: <>
            <span>Sostenibilidad en ahorradores de energía para hoteles.</span>
            <br/> <br/>
    
            <span> <b>Funcionamiento:</b> </span>
            <br/>
    
            <span>El ahorrador de energía es un dispositivo que libera la energía eléctrica de una habitación de hotel en el momento en que se inserta una tarjeta, la misma que se utiliza para abrir la puerta (con cerradura electrónica).</span>
            <br/>
    
            <span>Conmutar a través de contactores o directamente los circuitos de tomas, iluminación de la habitación, aparato de aire acondicionado, etc.</span>
            <br/>
    
            <span>Cuando se retira la tarjeta del ahorrador, los circuitos se apagan después de aproximadamente 60 segundos, dando tiempo al huésped para salir de la habitación. En este momento, dos luces piloto en el cuerpo del ahorrador se encienden para orientar y facilitar una futura inserción de la tarjeta.</span>
            <br/>
    
            <span>Solo funciona mediante la inserción de tarjeta con chip RFID, mifare (proximidad), debido a la existencia del sensor de radiofrecuencia.</span>
        </>,
    },
    
    energySaver5: {
        pt_br: `RFID 01 Interruptor de Luz e Não Perturbe`,
        en_us: `RFID 01 Light Switch and Do Not Disturb`,
        es_es: `Interruptor de Luz y No Perturbe RFID 01`
    },
    
    energySaver5Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>
    
            <span> <b>Funcionamento:</b> </span>
            <br/>
    
            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>
            
            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>
            
            <span>Quando o cartão é retirado do economizador os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>
            
            <span>Possui 1 interruptor para lâmpada e sistema de Não Perturbe. Somente funciona mediante a inserção de cartão com chip RFID, mifare (proximidade), devido à existência do sensor.</span>
        </>,
        en_us: <>
            <span>Sustainability in energy savers for hotels.</span>
            <br/> <br/>
    
            <span> <b>Operation:</b> </span>
            <br/>
    
            <span>The energy saver is a device that releases electrical energy in a hotel room when a card, the same one used to open the door (with electronic lock), is inserted into its slot.</span>
            <br/>
            
            <span>It switches through contactors or directly connects the circuits of outlets, room lighting, air conditioning unit, etc.</span>
            <br/>
            
            <span>When the card is removed from the saver, the circuits are turned off after approximately 60 seconds, allowing time for the guest to exit the room. At this moment, two pilot lights on the saver body light up to guide and facilitate future card insertion.</span>
            <br/>
            
            <span>It has 1 switch for a light and a Do Not Disturb system. It only works with the insertion of a card with RFID chip, mifare (proximity), due to the presence of the sensor.</span>
        </>,
        es_es: <>
            <span>Sostenibilidad en ahorradores de energía para hoteles.</span>
            <br/> <br/>
    
            <span> <b>Funcionamiento:</b> </span>
            <br/>
    
            <span>El ahorrador de energía es un dispositivo que libera la energía eléctrica de una habitación de hotel en el momento en que se inserta una tarjeta, la misma que se utiliza para abrir la puerta (con cerradura electrónica).</span>
            <br/>
            
            <span>Conmutar a través de contactores o directamente los circuitos de tomas, iluminación de la habitación, aparato de aire acondicionado, etc.</span>
            <br/>
            
            <span>Cuando se retira la tarjeta del ahorrador, los circuitos se apagan después de aproximadamente 60 segundos, dando tiempo al huésped para salir de la habitación. En este momento, dos luces piloto en el cuerpo del ahorrador se encienden para orientar y facilitar una futura inserción de la tarjeta.</span>
            <br/>
            
            <span>Posee 1 interruptor para lámpara y sistema de No Perturbe. Solo funciona mediante la inserción de tarjeta con chip RFID, mifare (proximidad), debido a la existencia del sensor.</span>
        </>,
    },    

    energySaver6: {
        pt_br: `RFID Não Perturbe`,
        en_us: `RFID Do Not Disturb`,
        es_es: `No Perturbe RFID`
    },
    
    energySaver6Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>
    
            <span> <b>Funcionamento:</b> </span>
            <br/>
    
            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>
    
            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>
    
            <span>Quando o cartão é retirado do economizador os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>
    
            <span>Possui sistema de Não Perturbe e somente funciona mediante a inserção de cartão com chip RFID, mifare (proximidade), devido à existência do sensor de rádio-frequência.</span>
        </>,
        en_us: <>
            <span>Sustainability in energy savers for hotels.</span>
            <br/> <br/>
    
            <span> <b>Operation:</b> </span>
            <br/>
    
            <span>The energy saver is a device that releases electrical energy in a hotel room when a card, the same one used to open the door (with electronic lock), is inserted into its slot.</span>
            <br/>
    
            <span>It switches through contactors or directly connects the circuits of outlets, room lighting, air conditioning unit, etc.</span>
            <br/>
    
            <span>When the card is removed from the saver, the circuits are turned off after approximately 60 seconds, allowing time for the guest to exit the room. At this moment, two pilot lights on the saver body light up to guide and facilitate future card insertion.</span>
            <br/>
    
            <span>It has a Do Not Disturb system and only works with the insertion of a card with RFID chip, mifare (proximity), due to the presence of the radio-frequency sensor.</span>
        </>,
        es_es: <>
            <span>Sostenibilidad en ahorradores de energía para hoteles.</span>
            <br/> <br/>
    
            <span> <b>Funcionamiento:</b> </span>
            <br/>
    
            <span>El ahorrador de energía es un dispositivo que libera la energía eléctrica de una habitación de hotel en el momento en que se inserta una tarjeta, la misma que se utiliza para abrir la puerta (con cerradura electrónica).</span>
            <br/>
    
            <span>Conmutar a través de contactores o directamente los circuitos de tomas, iluminación de la habitación, aparato de aire acondicionado, etc.</span>
            <br/>
    
            <span>Cuando se retira la tarjeta del ahorrador, los circuitos se apagan después de aproximadamente 60 segundos, dando tiempo al huésped para salir de la habitación. En este momento, dos luces piloto en el cuerpo del ahorrador se encienden para orientar y facilitar una futura inserción de la tarjeta.</span>
            <br/>
    
            <span>Posee un sistema de No Perturbe y solo funciona mediante la inserción de tarjeta con chip RFID, mifare (proximidad), debido a la existencia del sensor de radiofrecuencia.</span>
        </>,
    },
    
    energySaver7: {
        pt_br: `Ótico 01 Interruptor de Luz, Não Perturbe e Campainha`,
        en_us: `Optical 01 Light Switch, Do Not Disturb and Doorbell`,
        es_es: `Óptico 01 Interruptor de Luz, No Perturbe y Campana`
    },
    
    energySaver7Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>
    
            <span> <b>Funcionamento:</b> </span>
            <br/>
    
            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>
    
            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>
    
            <span>Quando o cartão é retirado do economizador os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>
    
            <span>Possui 1 interruptor para lâmpada, sistema de Não Perturbe, botão acionador de campainha e somente funciona mediante inserção de Cartão Magnético ou Cartão Chaveiro, devido à existência do sensor ótico.</span>
        </>,
        en_us: <>
            <span>Sustainability in energy savers for hotels.</span>
            <br/> <br/>
    
            <span> <b>Operation:</b> </span>
            <br/>
    
            <span>The energy saver is a device that releases electrical energy in a hotel room when a card, the same one used to open the door (with electronic lock), is inserted into its slot.</span>
            <br/>
    
            <span>It switches through contactors or directly connects the circuits of outlets, room lighting, air conditioning unit, etc.</span>
            <br/>
    
            <span>When the card is removed from the saver, the circuits are turned off after approximately 60 seconds, allowing time for the guest to exit the room. At this moment, two pilot lights on the saver body light up to guide and facilitate future card insertion.</span>
            <br/>
    
            <span>It has 1 switch for a light, a Do Not Disturb system, a doorbell button, and only works with the insertion of a Magnetic Card or Keychain Card, due to the presence of the optical sensor.</span>
        </>,
        es_es: <>
            <span>Sostenibilidad en ahorradores de energía para hoteles.</span>
            <br/> <br/>
    
            <span> <b>Funcionamiento:</b> </span>
            <br/>
    
            <span>El ahorrador de energía es un dispositivo que libera la energía eléctrica de una habitación de hotel en el momento en que se inserta una tarjeta, la misma que se utiliza para abrir la puerta (con cerradura electrónica).</span>
            <br/>
    
            <span>Conmutar a través de contactores o directamente los circuitos de tomas, iluminación de la habitación, aparato de aire acondicionado, etc.</span>
            <br/>
    
            <span>Cuando se retira la tarjeta del ahorrador, los circuitos se apagan después de aproximadamente 60 segundos, dando tiempo al huésped para salir de la habitación. En este momento, dos luces piloto en el cuerpo del ahorrador se encienden para orientar y facilitar una futura inserción de la tarjeta.</span>
            <br/>
    
            <span>Posee 1 interruptor para lámpara, sistema de No Perturbe, botón accionador de campana y solo funciona mediante la inserción de tarjeta magnética o tarjeta llavero, debido a la existencia del sensor óptico.</span>
        </>,
    },
    
    energySaver8: {
        pt_br: `Ótico 02 Interruptor de Luz, Não Perturbe e Campainha`,
        en_us: `Optical 02 Light Switch, Do Not Disturb and Doorbell`,
        es_es: `Óptico 02 Interruptor de Luz, No Perturbe y Campana`
    },
    
    energySaver8Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>
    
            <span> <b>Funcionamento:</b> </span>
            <br/>
    
            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>
    
            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>
    
            <span>Quando o cartão é retirado do economizador os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>
    
            <span>Possui 2 interruptores para lâmpada, sistema de Não Perturbe, botão acionador de campainha e somente funciona mediante a inserção de Cartão Magnético ou Cartão Chaveiro, devido à existência do sensor ótico.</span>
        </>,
        en_us: <>
            <span>Sustainability in energy savers for hotels.</span>
            <br/> <br/>
    
            <span> <b>Operation:</b> </span>
            <br/>
    
            <span>The energy saver is a device that releases electrical energy in a hotel room when a card, the same one used to open the door (with electronic lock), is inserted into its slot.</span>
            <br/>
    
            <span>It switches through contactors or directly connects the circuits of outlets, room lighting, air conditioning unit, etc.</span>
            <br/>
    
            <span>When the card is removed from the saver, the circuits are turned off after approximately 60 seconds, allowing time for the guest to exit the room. At this moment, two pilot lights on the saver body light up to guide and facilitate future card insertion.</span>
            <br/>
    
            <span>It has 2 switches for lights, a Do Not Disturb system, a doorbell button, and only works with the insertion of a Magnetic Card or Keychain Card, due to the presence of the optical sensor.</span>
        </>,
        es_es: <>
            <span>Sostenibilidad en ahorradores de energía para hoteles.</span>
            <br/> <br/>
    
            <span> <b>Funcionamiento:</b> </span>
            <br/>
    
            <span>El ahorrador de energía es un dispositivo que libera la energía eléctrica de una habitación de hotel en el momento en que se inserta una tarjeta, la misma que se utiliza para abrir la puerta (con cerradura electrónica).</span>
            <br/>
    
            <span>Conmutar a través de contactores o directamente los circuitos de tomas, iluminación de la habitación, aparato de aire acondicionado, etc.</span>
            <br/>
    
            <span>Cuando se retira la tarjeta del ahorrador, los circuitos se apagan después de aproximadamente 60 segundos, dando tiempo al huésped para salir de la habitación. En este momento, dos luces piloto en el cuerpo del ahorrador se encienden para orientar y facilitar una futura inserción de la tarjeta.</span>
            <br/>
    
            <span>Posee 2 interruptores para lámpara, sistema de No Perturbe, botón accionador de campana y solo funciona mediante la inserción de tarjeta magnética o tarjeta llavero, debido a la existencia del sensor óptico.</span>
        </>,
    },
    
    energySaver9: {
        pt_br: `Chave Contatora`,
        en_us: `Contactor Switch`,
        es_es: `Interruptor Contactor`
    },
    
    energySaver9Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>

            <span> <b>Referências:</b> </span>
            <ul>
                <li>WEG: CWC09-10-30V26</li>
                <li>Saga: Chave contatora 220V 20A - 30V26</li>
            </ul>
            <br />

            <span> <b>Especificações gerais:</b> </span>
            <ul>
                <li>Contatos Auxiliares: 1NA </li>
                <li>Contatos Principais (Força): 3NA </li>
                <li>Tensão da Bobina: 220V 60 Hz </li>
                <li>Número de polos: 3 </li>
                <li>Fusível máximo gL / gG (A): 20 </li>
                <li>Peso bobina CA / CC (kg): 20 </li>
                <li>Dimensões: Largura (mm): 45 x Altura (mm): 58 x Profundidade bobina CA / CC (mm): 52</li>
            </ul>
        </>,
        en_us: <>
            <span>Sustainability in energy savers for hotels.</span>
            <br/> <br/>

            <span> <b>References:</b> </span>
            <ul>
                <li>WEG: CWC09-10-30V26</li>
                <li>Saga: Contactor switch 220V 20A - 30V26</li>
            </ul>
            <br />

            <span> <b>General specifications:</b> </span>
            <ul>
                <li>Auxiliary contacts: 1NA </li>
                <li>Main Contacts (Power): 3NA </li>
                <li>Coil voltage: 220V 60 Hz </li>
                <li>Number of poles: 3 </li>
                <li>Maximum fuse gL / gG (A): 20 </li>
                <li>AC / DC coil weight (kg): 20 </li>
                <li>Dimensions: Width (mm): 45 x Height (mm): 58 x AC/DC coil depth (mm): 52</li>
            </ul>
        </>,
        es_es: <>
            <span>Sostenibilidad en los ahorradores de energía para hoteles.</span>
            <br/> <br/>
            <span> <b>Referencias:</b> </span>
            <ul>
                <li>WEG: CWC09-10-30V26</li>
                <li>Saga: Interruptor contactor 220V 20A - 30V26</li>
            </ul>
            <br />
            <span> <b>Especificaciones generales:</b> </span>
            <ul>
                <li>Contactos auxiliares: 1NA </li>
                <li>Contactos principales (alimentación): 3NA </li>
                <li>Tensión de bobina: 220V 60 Hz </li>
                <li>Número de polos: 3 </li>
                <li>Fusible máximo gL / gG (A): 20 </li>
                <li> Peso de la bobina CA / CC (kg): 20 </li>
                <li>Dimensiones: Anchura (mm): 45 x Altura (mm): 58 x Profundidad bobina AC/DC (mm): 52</li>
            </ul>
        </>,
    },
    
    energySaver10: {
        pt_br: `Ótico 01 Interruptor de Luz e Não Perturbe`,
        en_us: `Optical 01 Light Switch and Do Not Disturb`,
        es_es: `Óptico 01 Interruptor de Luz y No Perturbe`
    },
    
    energySaver10Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>
    
            <span> <b>Funcionamento:</b> </span>
            <br/>
    
            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>
    
            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>
    
            <span>Quando o cartão é retirado do economizador os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>
    
            <span>Possui 1 interruptor para lâmpada, sistema de Não Perturbe, botão acionador de campainha e somente funciona mediante inserção de Cartão Magnético ou Cartão Chaveiro, devido à existência do sensor ótico.</span>
        </>,
        en_us: <>
            <span>Sustainability in energy savers for hotels.</span>
            <br/> <br/>
    
            <span> <b>Operation:</b> </span>
            <br/>
    
            <span>The energy saver is a device that releases electrical energy in a hotel room when a card, the same one used to open the door (with electronic lock), is inserted into its slot.</span>
            <br/>
    
            <span>It switches through contactors or directly connects the circuits of outlets, room lighting, air conditioning unit, etc.</span>
            <br/>
    
            <span>When the card is removed from the saver, the circuits are turned off after approximately 60 seconds, allowing time for the guest to exit the room. At this moment, two pilot lights on the saver body light up to guide and facilitate future card insertion.</span>
            <br/>
    
            <span>It has 1 switch for a light, a Do Not Disturb system, a doorbell button, and only works with the insertion of a Magnetic Card or Keychain Card, due to the presence of the optical sensor.</span>
        </>,
        es_es: <>
            <span>Sostenibilidad en ahorradores de energía para hoteles.</span>
            <br/> <br/>
    
            <span> <b>Funcionamiento:</b> </span>
            <br/>
    
            <span>El ahorrador de energía es un dispositivo que libera la energía eléctrica de una habitación de hotel en el momento en que se inserta una tarjeta, la misma que se utiliza para abrir la puerta (con cerradura electrónica).</span>
            <br/>
    
            <span>Conmutar a través de contactores o directamente los circuitos de tomas, iluminación de la habitación, aparato de aire acondicionado, etc.</span>
            <br/>
    
            <span>Cuando se retira la tarjeta del ahorrador, los circuitos se apagan después de aproximadamente 60 segundos, dando tiempo al huésped para salir de la habitación. En este momento, dos luces piloto en el cuerpo del ahorrador se encienden para orientar y facilitar una futura inserción de la tarjeta.</span>
            <br/>
    
            <span>Posee 1 interruptor para lámpara, sistema de No Perturbe, botón accionador de campana y solo funciona mediante la inserción de tarjeta magnética o tarjeta llavero, debido a la existencia del sensor óptico.</span>
        </>,
    },    

    energySaver11: {
        pt_br: `Ótico Não Perturbe e Campainha`,
        en_us: `Optical Do Not Disturb and Doorbell`,
        es_es: `Óptico No Perturbe y Campana`
    },
    
    energySaver11Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>
    
            <span> <b>Funcionamento:</b> </span>
            <br/>
    
            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>
    
            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>
    
            <span>Quando o cartão é retirado do economizador, os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento, duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>
    
            <span>Possui sistema de Não Perturbe, botão acionador de campainha e somente funciona mediante a inserção de cartão com banda magnética ou cartão chaveiro, devido à existência do sensor ótico.</span>
        </>,
        en_us: <>
            <span>Sustainability in energy savers for hotels.</span>
            <br/> <br/>
    
            <span> <b>Operation:</b> </span>
            <br/>
    
            <span>The energy saver is a device that releases electrical energy in a hotel room when a card, the same one used to open the door (with electronic lock), is inserted into its slot.</span>
            <br/>
    
            <span>It switches through contactors or directly connects the circuits of outlets, room lighting, air conditioning unit, etc.</span>
            <br/>
    
            <span>When the card is removed from the saver, the circuits are turned off after approximately 60 seconds, allowing time for the guest to exit the room. At this moment, two pilot lights on the saver body light up to guide and facilitate future card insertion.</span>
            <br/>
    
            <span>It has a Do Not Disturb system, a doorbell button, and only works with the insertion of a Magnetic Card or Keychain Card, due to the presence of the optical sensor.</span>
        </>,
        es_es: <>
            <span>Sostenibilidad en ahorradores de energía para hoteles.</span>
            <br/> <br/>
    
            <span> <b>Funcionamiento:</b> </span>
            <br/>
    
            <span>El ahorrador de energía es un dispositivo que libera la energía eléctrica de una habitación de hotel en el momento en que se inserta una tarjeta, la misma que se utiliza para abrir la puerta (con cerradura electrónica).</span>
            <br/>
    
            <span>Conmutar a través de contactores o directamente los circuitos de tomas, iluminación de la habitación, aparato de aire acondicionado, etc.</span>
            <br/>
    
            <span>Cuando se retira la tarjeta del ahorrador, los circuitos se apagan después de aproximadamente 60 segundos, dando tiempo al huésped para salir de la habitación. En este momento, dos luces piloto en el cuerpo del ahorrador se encienden para orientar y facilitar una futura inserción de la tarjeta.</span>
            <br/>
    
            <span>Posee un sistema de No Perturbe, botón accionador de campana y solo funciona mediante la inserción de tarjeta magnética o tarjeta llavero, debido a la existencia del sensor óptico.</span>
        </>,
    },
    
    energySaver12: {
        pt_br: `Ótico 02 Interruptores de Luz`,
        en_us: `Optical 02 Light Switches`,
        es_es: `Óptico 02 Interruptores de Luz`
    },
    
    energySaver12Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>
    
            <span> <b>Funcionamento:</b> </span>
            <br/>
    
            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>
    
            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>
    
            <span>Quando o cartão é retirado do economizador, os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento, duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>
    
            <span>Possui 2 interruptores para lâmpadas e somente funciona mediante a inserção de cartão com banda magnética ou cartão chaveiro, devido à existência do sensor ótico.</span>
        </>,
        en_us: <>
            <span>Sustainability in energy savers for hotels.</span>
            <br/> <br/>
    
            <span> <b>Operation:</b> </span>
            <br/>
    
            <span>The energy saver is a device that releases electrical energy in a hotel room when a card, the same one used to open the door (with electronic lock), is inserted into its slot.</span>
            <br/>
    
            <span>It switches through contactors or directly connects the circuits of outlets, room lighting, air conditioning unit, etc.</span>
            <br/>
    
            <span>When the card is removed from the saver, the circuits are turned off after approximately 60 seconds, allowing time for the guest to exit the room. At this moment, two pilot lights on the saver body light up to guide and facilitate future card insertion.</span>
            <br/>
    
            <span>It has 2 switches for lights and only works with the insertion of a Magnetic Card or Keychain Card, due to the presence of the optical sensor.</span>
        </>,
        es_es: <>
            <span>Sostenibilidad en ahorradores de energía para hoteles.</span>
            <br/> <br/>
    
            <span> <b>Funcionamiento:</b> </span>
            <br/>
    
            <span>El ahorrador de energía es un dispositivo que libera la energía eléctrica de una habitación de hotel en el momento en que se inserta una tarjeta, la misma que se utiliza para abrir la puerta (con cerradura electrónica).</span>
            <br/>
    
            <span>Conmutar a través de contactores o directamente los circuitos de tomas, iluminación de la habitación, aparato de aire acondicionado, etc.</span>
            <br/>
    
            <span>Cuando se retira la tarjeta del ahorrador, los circuitos se apagan después de aproximadamente 60 segundos, dando tiempo al huésped para salir de la habitación. En este momento, dos luces piloto en el cuerpo del ahorrador se encienden para orientar y facilitar una futura inserción de la tarjeta.</span>
            <br/>
    
            <span>Posee 2 interruptores para lámpara y solo funciona mediante la inserción de tarjeta magnética o tarjeta llavero, debido a la existencia del sensor óptico.</span>
        </>,
    },
    
    energySavers: {
        pt_br: `Economizadores de energia`,
        en_us: `Energy Savers`,
        es_es: `Ahorradores de Energía`
    },
    
    energySaversDescription: {
        pt_br: `Economizadores de energia Saga Systems são dispositivos eficazes para reduzir o consumo elétrico em residências e empresas. Eles otimizam o uso de energia, diminuindo o desperdício e, consequentemente, reduzindo as contas de luz. Fáceis de instalar, esses equipamentos são uma escolha inteligente para quem busca economia e sustentabilidade.`,
        en_us: `Saga Systems energy savers are effective devices for reducing electricity consumption in homes and businesses. They optimize energy use, reducing waste and consequently lowering electricity bills. Easy to install, these devices are a smart choice for those seeking savings and sustainability.`,
        es_es: `Los ahorradores de energía de Saga Systems son dispositivos eficaces para reducir el consumo eléctrico en hogares y empresas. Optimiza el uso de la energía, disminuyendo el desperdicio y, en consecuencia, reduciendo las facturas de electricidad. Fáciles de instalar, estos equipos son una opción inteligente para quienes buscan ahorro y sostenibilidad.`
    },
    
    energySaversHomeDescription: {
        pt_br: `Sustentabilidade, economia de energia e prevenção de acidentes elétricos.`,
        en_us: `Sustainability, energy savings, and prevention of electrical accidents.`,
        es_es: `Sostenibilidad, ahorro de energía y prevención de accidentes eléctricos.`
    },    
}

export default ProductsTranslation;