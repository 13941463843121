import './style.scss';
import { Box, Grid, Typography } from "@mui/material";
import { useLanguageContext } from '../../../../contexts/LanguageContext';
import DefaultConstants from '../../../../data/Constants';
import Testimonials from '../../../../components/Testimonials';
import Clients from '../../../../components/Clients';


const About = (props) => {
    const { translate } = useLanguageContext();

    return <Grid container id='about-section' paddingBottom={8}>
        <Grid item xs={12} className='grid-content-section padding-none'>
            <Box className='wallpaper small' sx={{ backgroundImage: `url(${DefaultConstants.about.wallpaper})` }}>
                <Typography className='wallpaper-title'>
                    {translate(DefaultConstants.about.title)}
                </Typography>

                <Typography className='wallpaper-subtitle'>
                    {translate(DefaultConstants.about.subtitle)}
                </Typography>
            </Box>
        </Grid>

        <Grid item md={6} xs={12} className='grid-content-section textalign-center'>
            <Typography className='section-title'>
                {translate('connectingPeopleToSafety')}
            </Typography>

            {
                translate('aboutDescription1').map((d, index) => {
                    return <Typography variant='body1' className='position-relative' paddingY={0.4} key={index}>
                        {d}
                    </Typography>;
                })
            }
        </Grid>

        <Grid item md={6} xs={12} className='grid-content-section textalign-center'>
            <img src={DefaultConstants.about.images[0]} alt='about-1' width='100%' />
        </Grid>

        <Grid item md={1} xs={12} />

        <Grid item md={5} xs={12} className='grid-content-section textalign-right with-border'>
            <Typography className='section-title textalign-right'>
                {translate('mission')}
            </Typography>

            {
                translate('aboutDescription2').split('<br>').map((d, index) => {
                    return <Typography variant='body1' className='position-relative' paddingY={0.4} key={index}>
                        {d}
                    </Typography>;
                })
            }
        </Grid>

        <Grid item md={5} xs={12} className='grid-content-section textalign-left'>
            <Typography className='section-title textalign-left'>
                {translate('valuesAndVision')}
            </Typography>

            {
                translate('aboutDescription3').split('<br>').map((d, index) => {
                    return <Typography variant='body1' className='position-relative' paddingY={0.4} key={index}>
                        {d}
                    </Typography>;
                })
            }
        </Grid>

        <Grid item xs={12} className='grid-content-section textalign-center' paddingX={2}>
            <video controls>
                <source src={DefaultConstants.about.video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </Grid>

        <Grid item md={6} xs={12} className='grid-content-section textalign-center with-border'>
            <Typography className='section-title'>
                {translate('specialties')}
            </Typography>

            {
                translate('aboutDescription4').split('<br>').map((d, index) => {
                    return <Typography variant='body1' className='position-relative' paddingY={0.4} key={index}>
                        {d}
                    </Typography>;
                })
            }
        </Grid>

        <Grid item md={6} xs={12} className='grid-content-section'>
            <Typography className='section-title'>
                {translate('testimonials')}
            </Typography>

            <Testimonials />
        </Grid>

        <Grid item xs={12} className='grid-content-section'>
            <Typography className='section-title'>
                {translate('ourClients')}
            </Typography>

            <Clients />
        </Grid>
    </Grid>;
}


export default About;