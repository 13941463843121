const DefaultTranslation = {
    about: {
        pt_br: "Sobre",
        en_us: "About",
        es_es: "Sobre"
    },

    aboutUs: {
        pt_br: "Sobre nós",
        en_us: "About us",
        es_es: "Sobre nosotros"
    },

    back: {
        pt_br: "Voltar",
        en_us: "Go back",
        es_es: "Volver"
    },

    changeLanguage: {
        pt_br: "Alterar idioma",
        en_us: "Change the language",
        es_es: "Cambia el idioma"
    },

    connectingPeopleToSafety: {
        pt_br: "Conectando pessoas à segurança",
        en_us: "Connecting people to safety",
        es_es: "Conectando personas a la seguridad"
    },

    contact: {
        pt_br: "Contato",
        en_us: "Contact",
        es_es: "Contacto"
    },

    copyright: {
        pt_br: "© Copyright 2024. Todos os direitos reservados",
        en_us: "© Copyright 2024. All rights reserved",
        es_es: "© Copyright 2024. Todos los derechos reservados"
    },

    developedBy: {
        pt_br: "Desenvolvido por",
        en_us: "Developed by",
        es_es: "Desarrollado por"
    },

    email: {
        pt_br: "E-mail",
        en_us: "Email",
        es_es: "Correo electrónico"
    },

    english: {
        pt_br: "Inglês",
        en_us: "English",
        es_es: "Inglés"
    },

    error: {
        pt_br: "Erro",
        en_us: "Error",
        es_es: "Error"
    },

    home: {
        pt_br: "Home",
        en_us: "Home",
        es_es: "Inicio"
    },

    information: {
        pt_br: "Informação",
        en_us: "Information",
        es_es: "Información"
    },

    informations: {
        pt_br: "Informações",
        en_us: "Information",
        es_es: "Informaciones"
    },

    message: {
        pt_br: "Mensagem",
        en_us: "Message",
        es_es: "Mensaje"
    },

    mission: {
        pt_br: "Missão",
        en_us: "Mission",
        es_es: "Misión"
    },

    name: {
        pt_br: "Nome",
        en_us: "Name",
        es_es: "Nombre"
    },

    ourCatalog: {
        pt_br: "Nosso catálogo",
        en_us: "Our catalog",
        es_es: "Nuestro catálogo"
    },

    ourClients: {
        pt_br: "Nossos clientes",
        en_us: "Our clients",
        es_es: "Nuestros clientes"
    },

    ourProducts: {
        pt_br: "Nossos produtos",
        en_us: "Our products",
        es_es: "Nuestros productos"
    },

    ourServices: {
        pt_br: "Nossos serviços",
        en_us: "Our Services",
        es_es: "Nuestros servicios"
    },

    ourTeam: {
        pt_br: "Nosso time",
        en_us: "Our team",
        es_es: "Nuestro equipo"
    },

    phone: {
        pt_br: "Telefone",
        en_us: "Phone",
        es_es: "Teléfono"
    },

    portuguese: {
        pt_br: "Português",
        en_us: "Portuguese",
        es_es: "Portugués"
    },

    productNotFound: {
        pt_br: "Produto não encontrado",
        en_us: "Product not found",
        es_es: "Producto no encontrado"
    },

    products: {
        pt_br: "Produtos",
        en_us: "Products",
        es_es: "Productos"
    },

    readMore: {
        pt_br: "Ler mais",
        en_us: "Read more",
        es_es: "Leer más"
    },

    reliability: {
        pt_br: "Confiabilidade",
        en_us: "Reliability",
        es_es: "Confiabilidad"
    },

    sales: {
        pt_br: "Vendas",
        en_us: "Sales",
        es_es: "Ventas"
    },

    salesRepresentative: {
        pt_br: "Representante comercial",
        en_us: "Sales representative",
        es_es: "Representante de ventas"
    },

    seeProducts: {
        pt_br: "Ver produtos",
        en_us: "See products",
        es_es: "Ver productos"
    },

    send: {
        pt_br: "Enviar",
        en_us: "Send",
        es_es: "Enviar"
    },

    serviceNotFound: {
        pt_br: "Serviço não encontrado",
        en_us: "Service not found",
        es_es: "Servicio no encontrado"
    },

    services: {
        pt_br: "Serviços",
        en_us: "Services",
        es_es: "Servicios"
    },

    solutions: {
        pt_br: "Soluções",
        en_us: "Solutions",
        es_es: "Soluciones"
    },

    spanish: {
        pt_br: "Espanhol",
        en_us: "Spanish",
        es_es: "Español"
    },

    specialties: {
        pt_br: "Especialidades",
        en_us: "Specialties",
        es_es: "Especialidades"
    },

    success: {
        pt_br: "Sucesso",
        en_us: "Success",
        es_es: "Éxito"
    },

    subject: {
        pt_br: "Assunto",
        en_us: "Subject",
        es_es: "Asunto"
    },

    technicalAssistance: {
        pt_br: "Assistência técnica",
        en_us: "Technical assistance",
        es_es: "Asistencia técnica"
    },

    technicalSupport: {
        pt_br: "Suporte Técnico",
        en_us: "Technical support",
        es_es: "Soporte técnico"
    },

    testimonials: {
        pt_br: "Depoimentos",
        en_us: "Testimonials",
        es_es: "Testimonios"
    },

    timeLimitExceeded: {
        pt_br: "Limite de tempo excedido",
        en_us: "Time limit exceeded",
        es_es: "Límite de tiempo excedido"
    },

    valuesAndVision: {
        pt_br: "Valores e visão",
        en_us: "Values and vision",
        es_es: "Valores y visión"
    },

    warning: {
        pt_br: "Atenção",
        en_us: "Warning",
        es_es: "Advertencia"
    },
}

export default DefaultTranslation;
