const ServicesTranslation = {
    _24HourTechnicalAssistance: {
        pt_br: "Assistência Técnica 24 horas",
        en_us: "24 Hour Technical Assistance",
        es_es: "Asistencia Técnica 24 horas"
    },

    _24HourTechnicalAssistanceDescription: {
        pt_br: <>
            <span>Agora você já pode abrir um chamado de suporte técnico da Saga Systems de forma rápida e fácil.</span>
            <br/> <br/>

            <span>Basta se cadastrar no site e criar um chamado preenchendo o formulário. Nosso departamento técnico fará o atendimento e você receberá um relatório por e-mail a cada atualização do seu chamado!</span>
            <br/> <br/>
            
            <ul>
                <li>Contrato de Assistência técnica com suporte apenas telefônico</li>
                <li>Contrato de Assistência técnica com suporte telefônico, manutenções preventivas e corretivas sem peças</li>
                <li>Contrato de Assistência técnica com suporte telefônico, manutenções preventivas e corretivas com peças</li>
            </ul>

            <span>Solicite o seu contrato através de um de nossos meios de contato.</span>
        </>,
        en_us: <>
            <span>You can now open a technical support ticket with Saga Systems quickly and easily.</span>
            <br/> <br/>

            <span>Just register on the website and create a ticket by filling out the form. Our technical department will attend to you, and you will receive a report via email with every update on your ticket!</span>
            <br/> <br/>
            
            <ul>
                <li>Technical assistance contract with telephone support only.</li>
                <li>Technical assistance contract with telephone support, preventive and corrective maintenance without parts.</li>
                <li>Technical assistance contract with telephone support, preventive and corrective maintenance with parts.</li>
            </ul>

            <span>Request your contract through one of our contact methods.</span>
        </>,
        es_es: <>
            <span>Ahora puedes abrir un ticket de soporte técnico con Saga Systems de manera rápida y fácil.</span>
            <br/> <br/>

            <span>Simplemente regístrate en el sitio web y crea un ticket completando el formulario. ¡Nuestro departamento técnico te atenderá y recibirás un informe por correo electrónico con cada actualización de tu ticket!</span>
            <br/> <br/>
            
            <ul>
                <li>Contrato de asistencia técnica con soporte solo telefónico.</li>
                <li>Contrato de asistencia técnica con soporte telefónico, mantenimiento preventivo y correctivo sin piezas.</li>
                <li>Contrato de asistencia técnica con soporte telefónico, mantenimiento preventivo y correctivo con piezas.</li>
            </ul>

            <span>Solicita tu contrato a través de uno de nuestros métodos de contacto.</span>
        </>,
    },

    _24HourTechnicalAssistanceHomeDescription: {
        pt_br: "Abra um chamado de suporte técnico da Saga Systems Brasil quando precisar.",
        en_us: "Open a technical support ticket with Saga Systems Brazil whenever you need.",
        es_es: "Abre un ticket de soporte técnico con Saga Systems Brasil cuando lo necesites."
    },

    installationPeripheralsLocks: {
        pt_br: "Instalação de Periféricos e Fechaduras",
        en_us: "Installation of Peripherals and Locks",
        es_es: "Instalación de Periféricos y Cerraduras"
    },

    installationPeripheralsLocksDescription: {
        pt_br: <>
            <span>Contrate nossos serviços especializados para instalação de periféricos e fechaduras no seu empreendimento. Instalamos fechaduras em portas comuns, portas corta-fogo, leitores em elevador e leitores em portas de acessos restritos.</span>
        </>,
        en_us: <>
            <span>Hire our specialized services for the installation of peripherals and locks in your establishment. We install locks on regular doors, fire doors, elevator readers, and readers on restricted access doors.</span>
        </>,
        es_es: <>
            <span>Contrata nuestros servicios especializados para la instalación de periféricos y cerraduras en tu establecimiento. Instalamos cerraduras en puertas comunes, puertas corta-fuego, lectores en elevadores y lectores en puertas de acceso restringido.</span>
        </>,
    },

    installationPeripheralsLocksHomeDescription: {
        pt_br: "Instalamos fechaduras em portas comuns, portas corta-fogo, leitores em elevador e leitores em portas de acessos restritos.",
        en_us: "We install locks on regular doors, fire doors, elevator readers, and readers on restricted access doors.",
        es_es: "Instalamos cerraduras en puertas comunes, puertas corta-fuego, lectores en elevadores y lectores en puertas de acceso restringido."
    },

    preventiveCorrectiveMaintenance: {
        pt_br: "Manutenções Preventivas e Corretivas",
        en_us: "Preventive and Corrective Maintenance",
        es_es: "Mantenimientos Preventivos y Correctivos"
    },

    preventiveCorrectiveMaintenanceDescription: {
        pt_br: <>
            <span>Atendimento local para correção de falhas mecânicas ou no sistema.</span>
            <br/> 
            
            <br/>
            <span>
                <b>Manutenções Preventivas:</b> Assegure a funcionalidade e durabilidade das suas fechaduras eletrônicas, aumentando a eficiência, confiabilidade e segurança de seus equipamentos.
                Prolongue a vida útil destes equipamentos, diminuindo o desgaste eletrônico e mecânico de partes e peças:
            </span>
            <ul>
                <li>Limpeza de leitores e testes de funcionalidade das fechaduras e cofres</li>
                <li>Gravação das chaves emergenciais</li>
                <li>Atualização das fechaduras com informações precisas</li>
            </ul>

            <br/>
            <span>
                <b>Manutenções Corretivas:</b> Atendimento no local para correção de falhas mecânicas ou no sistema.
            </span>
        </>,
        en_us: <>
            <span>On-site service for correcting mechanical or system failures.</span>
            <br/>

            <br/>
            <span>
                <b>Preventive Maintenance:</b> Ensure the functionality and durability of your electronic locks, increasing the efficiency, reliability, and security of your equipment. Extend the life of these devices by reducing electronic and mechanical wear on parts:
            </span>
            <ul>
                <li>Cleaning of readers and functionality tests of locks and safes.</li>
                <li>Recording of emergency keys.</li>
                <li>Updating locks with accurate information.</li>
            </ul>

            <br/>
            <span>
                <b>Corrective Maintenance:</b> On-site service for correcting mechanical or system failures.
            </span>
        </>,
        es_es: <>
            <span>Servicio local para corregir fallas mecánicas o en el sistema.</span>
            <br/>

            <br/>
            <span>
                <b>Mantenimientos Preventivos:</b> Asegure la funcionalidad y durabilidad de sus cerraduras electrónicas, aumentando la eficiencia, confiabilidad y seguridad de sus equipos. Prolongue la vida útil de estos equipos, disminuyendo el desgaste electrónico y mecánico de las partes:
            </span>
            <ul>
                <li>Limpieza de lectores y pruebas de funcionalidad de cerraduras y cajas fuertes.</li>
                <li>Grabación de llaves de emergencia.</li>
                <li>Actualización de cerraduras con información precisa.</li>
            </ul>

            <br/>
            <span>
                <b>Mantenimientos Correctivos:</b> Atención en el lugar para corregir fallas mecánicas o en el sistema.
            </span>
        </>,
    },

    preventiveCorrectiveMaintenanceHomeDescription: {
        pt_br: "Atendimento local para correção de falhas mecânicas ou no sistema.",
        en_us: "On-site service for correcting mechanical or system failures.",
        es_es: "Servicio local para corregir fallas mecánicas o en el sistema."
    },

    recyclingTrainings: {
        pt_br: "Treinamentos de reciclagem",
        en_us: "Recycling Trainings",
        es_es: "Capacitación de Reciclaje"
    },

    recyclingTrainingsDescription: {
        pt_br: <>
            <span>Treinamento do sistema de fechaduras eletrônicas aos principais departamentos responsáveis pela operação do sistema.</span>
            <br/> 
            
            <br/>
            <span>
                <b>Gerência Geral:</b> Treinamento completo de todas as rotinas do sistema:
            </span>
            <ul>
                <li>Procedimentos de chaves mestras</li>
                <li>Procedimento de chaves de segurança e chaves especiais</li>
                <li>Procedimentos de auditoria</li>
                <li>Procedimentos de cadastro de usuários e níveis de acesso</li>
            </ul>

            <br/>
            <span>
                <b>Recepção:</b> Treinamento das rotinas operacionais da recepção:
            </span>
            <ul>
                <li>Procedimento de gravação de chaves para hóspede</li>
                <li>Ações de segurança em caso de perda de chaves</li>
                <li>Procedimentos para gerar cópias de chaves, chaves múltiplas, abertura única, etc.</li>
            </ul>

            <br/>
            <span>
                <b>Manutenção:</b> Treinamento voltado para conhecimento específico de operação do produto:
            </span>
            <ul>
                <li>Entendimento da funcionalidade da fechadura, reconhecimento de sinais de alerta, identificação de baterias fracas, etc.</li>
                <li>Procedimentos de segurança para utilização de chaves mestras</li>
                <li>Procedimentos para troca de baterias</li>
                <li>Procedimentos de montagem e desmontagem da fechadura</li>
                <li>Procedimentos de abertura de emergência da fechadura</li>
            </ul>

            <br/>
            <span>
                <b>Governança:</b> Treinamento voltado à limpeza e manutenção das fechaduras, além do conhecimento de funcionamento das mesmas:
            </span>
            <ul>
                <li>Entendimento da funcionalidade da fechadura, reconhecimento de sinais de alerta, identificação de baterias fracas, etc.</li>
                <li>Procedimentos de segurança para utilização de chaves mestras</li>
                <li>Procedimentos de limpeza e manutenção das fechaduras</li>
            </ul>
        </>,
        en_us: <>
            <span>Training on the electronic lock system for the main departments responsible for the system's operation.</span>
            <br/>

            <br/>
            <span>
                <b>General Management:</b> Comprehensive training on all system routines:
            </span>
            <ul>
                <li>Master key procedures.</li>
                <li>Security key and special key procedures.</li>
                <li>Audit procedures.</li>
                <li>User registration and access level procedures.</li>
            </ul>

            <br/>
            <span>
                <b>Reception:</b> Training on operational routines of the reception:
            </span>
            <ul>
                <li>Key recording procedures for guests.</li>
                <li>Security actions in case of key loss.</li>
                <li>Procedures to generate key copies, multiple keys, single openings, etc.</li>
            </ul>

            <br/>
            <span>
                <b>Maintenance:</b> Training focused on specific knowledge of product operation:
            </span>
            <ul>
                <li>Understanding the lock's functionality, recognizing warning signals, identifying weak batteries, etc.</li>
                <li>Safety procedures for using master keys.</li>
                <li>Battery replacement procedures.</li>
                <li>Lock assembly and disassembly procedures.</li>
                <li>Emergency opening procedures for the lock.</li>
            </ul>

            <br/>
            <span>
                <b>Governance:</b> Training focused on cleaning and maintaining locks, as well as knowledge of their operation:
            </span>
            <ul>
                <li>Understanding the lock's functionality, recognizing warning signals, identifying weak batteries, etc.</li>
                <li>Safety procedures for using master keys.</li>
                <li>Cleaning and maintenance procedures for locks.</li>
            </ul>
        </>,
        es_es: <>
            <span>Capacitación sobre el sistema de cerraduras electrónicas para los principales departamentos responsables de la operación del sistema.</span>
            <br/>

            <br/>
            <span>
                <b>Gerencia General:</b> Capacitación completa sobre todas las rutinas del sistema:
            </span>
            <ul>
                <li>Procedimientos de llaves maestras.</li>
                <li>Procedimiento de llaves de seguridad y llaves especiales.</li>
                <li>Procedimientos de auditoría.</li>
                <li>Procedimientos de registro de usuarios y niveles de acceso.</li>
            </ul>

            <br/>
            <span>
                <b>Recepción:</b> Capacitación sobre las rutinas operativas de la recepción:
            </span>
            <ul>
                <li>Procedimientos de grabación de llaves para huéspedes.</li>
                <li>Acciones de seguridad en caso de pérdida de llaves.</li>
                <li>Procedimientos para generar copias de llaves, llaves múltiples, aperturas únicas, etc.</li>
            </ul>

            <br/>
            <span>
                <b>Mantenimiento:</b> Capacitación centrada en el conocimiento específico del funcionamiento del producto:
            </span>
            <ul>
                <li>Comprensión de la funcionalidad de la cerradura, reconocimiento de señales de advertencia, identificación de baterías débiles, etc.</li>
                <li>Procedimientos de seguridad para el uso de llaves maestras.</li>
                <li>Procedimientos para el reemplazo de baterías.</li>
                <li>Procedimientos de montaje y desmontaje de la cerradura.</li>
                <li>Procedimientos de apertura de emergencia para la cerradura.</li>
            </ul>

            <br/>
            <span>
                <b>Gobernanza:</b> Capacitación centrada en la limpieza y mantenimiento de cerraduras, así como el conocimiento de su funcionamiento:
            </span>
            <ul>
                <li>Comprensión de la funcionalidad de la cerradura, reconocimiento de señales de advertencia, identificación de baterías débiles, etc.</li>
                <li>Procedimientos de seguridad para el uso de llaves maestras.</li>
                <li>Procedimientos de limpieza y mantenimiento de cerraduras.</li>
            </ul>
        </>,
    },

    recyclingTrainingsHomeDescription: {
        pt_br: "Treinamento do sistema de fechaduras eletrônicas aos principais departamentos responsáveis pela operação do sistema.",
        en_us: "Training on the electronic lock system for the main departments responsible for the system's operation.",
        es_es: "Capacitación sobre el sistema de cerraduras electrónicas para los principales departamentos responsables de la operación del sistema."
    },

    specializedTechnicalTeam: {
        pt_br: "Equipe Técnica Especializada",
        en_us: "Specialized Technical Team",
        es_es: "Equipo Técnico Especializado"
    },

    specializedTechnicalTeamDescription: {
        pt_br: <>
            <span>Organize seu sistema de fechaduras eletrônicas e aumente seu nível de segurança.</span>
            <ul>
                <li>Revisão da arquitetura geral do sistema, organizando os acessos e autorizações de colaboradores.</li>
                <li>Reciclagem de senhas de acesso.</li>
                <li>Organização da lista geral de portas instaladas.</li>
                <li>Reformulação da lista de chaves mestras e acessos.</li>
            </ul>
        </>,
        en_us: <>
            <span>Organize your electronic lock system and increase your security level.</span>
            <ul>
                <li>Review the overall system architecture, organizing employee accesses and authorizations.</li>
                <li>Recycling of access passwords.</li>
                <li>Organization of the general list of installed doors.</li>
                <li>Reformulation of the list of master keys and accesses.</li>
            </ul>
        </>,
        es_es: <>
            <span>Organiza tu sistema de cerraduras electrónicas y aumenta tu nivel de seguridad.</span>
            <ul>
                <li>Revisión de la arquitectura general del sistema, organizando los accesos y autorizaciones de los empleados.</li>
                <li>Reciclaje de contraseñas de acceso.</li>
                <li>Organización de la lista general de puertas instaladas.</li>
                <li>Reformulación de la lista de llaves maestras y accesos.</li>
            </ul>
        </>,
    },

    specializedTechnicalTeamHomeDescription: {
        pt_br: "Organize seu sistema de fechaduras eletrônicas e aumente seu nível de segurança.",
        en_us: "Organize your electronic lock system and increase your security level.",
        es_es: "Organiza tu sistema de cerraduras electrónicas y aumenta tu nivel de seguridad."
    },

    systemsRestructuring: {
        pt_br: "Reestruturação de Sistemas",
        en_us: "Systems Restructuring",
        es_es: "Reestructuración de Sistemas"
    },

    systemsRestructuringDescription: {
        pt_br: <>
            <span>Organize seu sistema de fechaduras eletrônicas e aumente seu nível de segurança.</span>
        </>,
        en_us: <>
            <span>Organize your electronic lock system and increase your security level.</span>
        </>,
        es_es: <>
            <span>Organiza tu sistema de cerraduras electrónicas y aumenta tu nivel de seguridad.</span>
        </>,
    },

    systemsRestructuringHomeDescription: {
        pt_br: "Organize seu sistema de fechaduras eletrônicas e aumente o nível de segurança do seu empreendimento.",
        en_us: "Organize your electronic lock system and enhance the security level of your establishment.",
        es_es: "Organiza tu sistema de cerraduras electrónicas y aumenta el nivel de seguridad de tu negocio."
    },
};

export default ServicesTranslation;