const TestimonialsTranslation = {
    testimonial1Message: {
        pt_br: "Sou supervisora das recepções dos Hotéis Hilton Garden Inn Santo André e Go Inn Santo André. Todas as fechaduras dos dois hotéis foram fornecidas pela Saga Systems! Já estamos em funcionamento por 8 meses, e até hoje nunca tive nenhum problema com as fechaduras, além do suporte personalizado que os técnicos nos oferecem a qualquer momento.  Agradeço por todo o serviço e atenção prestados por essa empresa. São verdadeiros parceiros.",
        en_us: "I am the reception supervisor at Hilton Garden Inn Santo André and Go Inn Santo André Hotels. All the locks of both hotels were provided by Saga Systems! We have been operating for 8 months now, and so far, I have never had any problems with the locks, in addition to the personalized support that the technicians offer us at any time. I am grateful for all the service and attention provided by this company. They are true partners.",
        es_es: "Soy supervisora de recepciones en los Hoteles Hilton Garden Inn Santo André y Go Inn Santo André. ¡Todas las cerraduras de ambos hoteles fueron proporcionadas por Saga Systems! Llevamos 8 meses en funcionamiento, y hasta el día de hoy, nunca he tenido ningún problema con las cerraduras, además del soporte personalizado que los técnicos nos ofrecen en cualquier momento. Agradezco todo el servicio y la atención brindados por esta empresa. Son verdaderos socios."
    },

    testimonial2Message: {
        pt_br: "Gostaríamos de agradecer os serviços e atendimento prestado pela SAGA SYSTEMS BRASIL! Nós realmente apreciamos e temos a certeza de uma parceria e satisfação. Tivemos nossas necessidades atendidas e estamos muito satisfeito com a reciprocidade e profissionalismo que atuam em seu segmento.",
        en_us: "We would like to thank SAGA SYSTEMS BRASIL for the services and customer care provided! We truly appreciate it and are confident in our partnership and satisfaction. Our needs were met, and we are very satisfied with the reciprocity and professionalism in which they operate in their sector.",
        es_es: "¡Nos gustaría agradecer a SAGA SYSTEMS BRASIL por los servicios y la atención brindados! Realmente lo apreciamos y estamos seguros de nuestra satisfacción y alianza. Nuestras necesidades fueron satisfechas y estamos muy contentos con la reciprocidad y el profesionalismo con el que operan en su sector."
    },

    testimonial3Message: {
        pt_br: "Tive um ótimo atendimento, inclusive no pós venda.",
        en_us: "I had great service, including after-sales support.",
        es_es: "Tuve un excelente servicio, incluso en el soporte postventa."
    },

    testimonial4Message: {
        pt_br: "O produto e o atendimento são de primeira qualidade, além de que o software é muito prático e não deixa nenhum gap de segurança. É importante também destacar a implantação, que atendeu nossas necessidades particulares de forma muito profissional. Além disso, o suporte pós-implantação que minha equipe está recebendo é ótimo.",
        en_us: "The product and customer service are top quality, and the software is very practical, leaving no security gaps. It's also important to highlight the implementation, which met our specific needs in a very professional manner. Furthermore, the post-implementation support my team is receiving is excellent.",
        es_es: "El producto y el servicio al cliente son de primera calidad, además de que el software es muy práctico y no deja ningún vacío de seguridad. También es importante destacar la implementación, que atendió nuestras necesidades particulares de manera muy profesional. Además, el soporte post-implementación que mi equipo está recibiendo es excelente."
    },

    testimonial5Message: {
        pt_br: "Diferentes dos concorrentes, a SAGA Systems tem pós vendas que realmente funciona. Dos materiais que comprei, passados anos não precisei de assistência técnica. Isto é ótimo.",
        en_us: "Unlike the competitors, SAGA Systems has an after-sales service that really works. Of the materials I bought, years have passed and I haven't needed any technical assistance. This is great.",
        es_es: "A diferencia de los competidores, SAGA Systems tiene un servicio postventa que realmente funciona. De los materiales que compré, han pasado años y no he necesitado asistencia técnica. Esto es genial."
    },
}

export default TestimonialsTranslation;
